import { createSlice } from '@reduxjs/toolkit';
import { calculateUnit, getConnectorDetails } from './action';

const initialState = {
    loading: false,
    redirectToAddMoney: false,
    calculatedEnergy: 0,
    calculatedAmount: 0,
    givenAmount: 0,
    givenCharge: 0,
    balance: null,
    error: null,
    ConnectorDetails: null,
    isZeroTarrif: false,
};

const energySlice = createSlice({
    name: 'energyCalculation',
    initialState,
    reducers: {
        setEnergySlice: calculateUnit,
        setCharge: (state, action) => {
            state.calculatedEnergy = action.payload;
        },
        setPrice: (state, action) => {
            state.calculatedAmount = action.payload;
        },

        resetState: (state) => {
            state.calculatedEnergy = null;
            state.calculatedAmount = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(calculateUnit.pending, (state) => {
                state.error = '';
                state.loading = true;
                state.calculatedEnergy = 0;
                state.calculatedAmount = 0;
                state.redirectToAddMoney = false;
            })
            .addCase(calculateUnit.fulfilled, (state, action) => {
                state.loading = false;
                state.error = '';
                state.calculatedEnergy = action.payload?.payload?.calculatedCharge
                    ? action.payload?.payload?.calculatedCharge
                    : action.payload?.payload?.givenCharge;
                state.calculatedAmount = action.payload?.payload?.caluclatedPrice
                    ? action.payload?.payload?.caluclatedPrice
                    : action.payload?.payload?.givenAmount;
                state.redirectToAddMoney = action.payload?.payload?.redirectToAddMoney;
                state.givenCharge = action.payload?.payload?.givenCharge;
                state.givenAmount = action.payload?.payload?.givenAmount;
                state.balance = action.payload?.payload?.walletBalance;
            })
            .addCase(calculateUnit.rejected, (state, action) => {
                state.calculatedEnergy = 0;
                state.calculatedAmount = 0;
                state.loading = false;
                state.error = action.error.message;
                state.redirectToAddMoney = false;
                state.balance = action.payload?.payload?.walletBalance;
            })
            .addCase(getConnectorDetails.pending, (state) => {
                state.error = '';
                state.loading = true;
                state.ConnectorDetails = null;
                state.isZeroTarrif = false;
            })
            .addCase(getConnectorDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = '';
                state.ConnectorDetails = action?.payload?.payload;
                state.isZeroTarrif =
                    action?.payload?.payload?.connector_data?.charging_point?.tariff?.tax
                        ?.value == 0
                        ? true
                        : false;
            })
            .addCase(getConnectorDetails.rejected, (state, action) => {
                state.isZeroTarrif = false;
                state.loading = false;
                state.error = action.error.message;
                state.ConnectorDetails = action?.payload?.payload;
            });
    },
});
export const { setEnergySlice, setCharge, setPrice, resetState } = energySlice.actions;
export default energySlice.reducer;
