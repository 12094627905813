import React, { useEffect, useState } from 'react';
import './HomeView.scss';
import HomeLayout from '../../../Layouts/HomeLayout/HomeLayout';
import ModernCard from '../../../Components/HomeCards/ModernCard/ModernCard';
import fan from '../../../Assets/pngIcon/fan.png';
import gas from '../../../Assets/pngIcon/gas.png';
import tree from '../../../Assets/pngIcon/tree.png';
import { useDispatch, useSelector } from 'react-redux';
import { createWallet, getHomeDetails } from './redux/homeSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import InvoiceDownload from '../../../Components/Common/InvoiceDownload/InvoiceDownload';
import { getChargingInvoice } from '../WalletView/redux/walletSlice';
import { getProfileDetails } from '../MyProfileView/redux/profileSlice';
import { Button } from '../../../Components/Common';
import { Pathname } from '../../../Router/Pathname';
import LeoButton from '../../../Components/Common/LeoButton/LeoButton';

const HomeView = () => {
    const { homeInfo, loading } = useSelector((store) => store.home);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const transactionId = location?.state?.transactionId;

    const { invoiceDetails } = useSelector((store) => store.wallet);
    const [isDisplayInvoice, setIsDisplayInvoice] = useState(false);
    const currentUser =
        useSelector(
            (store) => store?.profile?.profileInfo?.payload?.customer?.user_name
        ) || 'User';

    useEffect(async () => {
        let data = await dispatch(getHomeDetails());
        dispatch(getProfileDetails());
        //   if (!data?.payload?.payload?.is_onboarded) {
        //         navigate('/userinfo');
        //     }
    }, []);

    // useEffect(() => {
    //     console.log("homeInfo",homeInfo)
    //     if (homeInfo !== null && !homeInfo?.payload?.is_onboarded) {
    //         navigate('/userinfo');
    //     }
    // }, [homeInfo]);

    const handleCloseInvoice = () => {
        setIsDisplayInvoice(false);
        navigate(location.pathname, { replace: true });
    };

    useEffect(() => {
        if (transactionId) {
            dispatch(getChargingInvoice({ id: transactionId }));
            setIsDisplayInvoice(true);
            setTimeout(() => {}, 2000);
            if (invoiceDetails?.success === true) {
            }
        }
    }, [transactionId]);

    return (
        <HomeLayout>
            <section className="HomeView responsive--homeView ">
                <div className="HomeView--Header responsive--header">
                    <p className="HomeView--profileName">
                        Hi,{' '}
                        <span
                            style={{
                                color: '#00aa74',
                            }}
                        >
                            {currentUser}
                        </span>
                    </p>
                    <LeoButton onClick={() => navigate(Pathname.CHAT_BOT, { state: { currentUser } })} />
                    <div className="HomeView--Card">
                        <ModernCard
                            heading="Total Charging Sessions"
                            subheading={
                                homeInfo?.payload?.session_count
                                    ? homeInfo?.payload?.session_count
                                    : 0
                            }
                            icon={<img src={gas} alt="icon" />}
                        />
                        <ModernCard
                            heading="Total Units Consumed"
                            subheading={`${Number(
                                homeInfo?.payload?.unit_consumed
                                    ? homeInfo?.payload?.unit_consumed
                                    : 0
                            ).toFixed(2)} kwh`}
                            icon={<img src={fan} alt="icon" />}
                        />
                    </div>
                    <ModernCard
                        heading="Carbon Emissions Saved"
                        subheading={`${parseFloat(
                            homeInfo?.payload?.carbon_emission
                                ? homeInfo?.payload?.carbon_emission
                                : 0
                        ).toFixed(2)} Kg`}
                        className={'HomeView--treeIcon'}
                        icon={<img src={tree} alt="icon" />}
                    />
                </div>
            </section>
            {isDisplayInvoice && Boolean(invoiceDetails) && (
                <InvoiceDownload
                    openModal={isDisplayInvoice}
                    invoiceClose={handleCloseInvoice}
                    ocppTransactionId={transactionId}
                    invoiceId={''}
                />
            )}
        </HomeLayout>
    );
};

export default HomeView;
