// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 767px) {
  .mobileView {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .mobileView {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/MobileView/MobileView.scss"],"names":[],"mappings":"AAAA;EACI;IACI,cAAA;EACN;AACF;AAGA;EACM;IACE,aAAA;EADN;AACF","sourcesContent":["@media screen and (max-width: 767px) {\n    .mobileView {\n        display: block;\n    }\n}\n\n\n@media screen and (min-width: 768px) {\n      .mobileView {\n        display: none;\n      }\n    // .large-screen-message {\n    //     display: block;\n    //     text-align: center;\n    //     font-size: 24px;\n    //     padding: 20px;\n    //     background-color: #f0f0f0;\n    //     border: 1px solid #ccc;\n    //     background-color: red;\n    // }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
