// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyVehivleView {
  padding-inline: 2rem;
  background-color: white;
  height: 90vh;
  overflow-y: auto;
  position: relative;
}
.MyVehivleView .MyVehivleView--addVehicle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 3rem;
}
.MyVehivleView .MyVehivleView--addVehicle p {
  font-size: 18px;
  font-weight: 600;
}
.MyVehivleView .chatBotIcon {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 64px;
  height: 64px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/MyVehiclesView/MyVehiclesView.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACR;AACQ;EACI,eAAA;EACA,gBAAA;AACZ;AAGI;EACI,eAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;AADR","sourcesContent":[".MyVehivleView {\n    padding-inline: 2rem;\n    background-color: white;\n    height: 90vh;\n    overflow-y: auto;\n    position: relative;\n\n    .MyVehivleView--addVehicle {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        padding-block: 3rem;\n        \n        p {\n            font-size: 18px;\n            font-weight: 600;\n        }\n    }\n\n    .chatBotIcon {\n        position: fixed;\n        bottom: 80px;\n        right: 20px;\n        width: 64px;\n        height: 64px;\n        background: white;\n        border-radius: 50%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        cursor: pointer;\n        z-index: 1000;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
