import React from "react";

const SelectedStationCard = ({ station, sender }) => {
  if (!station || sender !== "user") {
    return null; // Return nothing if no station or sender is not 'user'
  }

  return (
    <div className="station-list">
      <div className="station-card">
        <img src={station.image} alt={station.name} className="station-image" />
        <div className="station-details">
          <p className="station-name">{station.name}</p>
          <p className="selected-station-session">Last session: {station.lastSession}</p>
        </div>
      </div>
      <p className="selected-text">Selected station</p>
    </div>
  );
};

export default SelectedStationCard;
