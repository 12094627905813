import { createAsyncThunk } from '@reduxjs/toolkit';
import Server from '../../../../../Server';
import { CALCULATE_ENERGY, CONNECTOR_DETAIL, END_PLAN_SUBSCRIPTION } from '../../../../../API/Endpoint';

export const calculateUnit = createAsyncThunk(
    'home/calculateUnit',
    async ({ connector_id, amount, unit }) => {
        try {
            const res = await Server.post(
                CALCULATE_ENERGY,
                {
                    connector_id: connector_id,
                    amount: amount,
                    energy: unit,
                },
                true
            );
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const fetchCalculatedEnergy = createAsyncThunk(
    'home/fetchCalculatedEnergy',
    async ({ connector_id, unit }) => {
        try {
            const res = await Server.post(
                CALCULATE_ENERGY,
                {
                    connector_id: connector_id,
                    energy: unit,
                },
                true
            );
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const fetchCalculatedAmount = createAsyncThunk(
    'home/fetchCalculatedAmount',
    async ({ connector_id, amount }) => {
        try {
            const res = await Server.post(
                CALCULATE_ENERGY,
                {
                    connector_id: connector_id,
                    amount: amount,
                },
                true
            );
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const getConnectorDetails = createAsyncThunk(
    'home/getConnectorDetails',
    async (connector_id) => {
        try {
            const res = await Server.get(`${CONNECTOR_DETAIL}/${connector_id}`, true);
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);


export const getEndPlanDetails = createAsyncThunk(
    'home/getEndPlanDetails',
    async (charging_point_id) => {
        try {
            const res = await Server.get(`${END_PLAN_SUBSCRIPTION}?charging_point_id=${charging_point_id
}`, true);
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);
