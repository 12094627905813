// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal1 {
  position: absolute;
  right: 3rem;
  border-radius: 12px;
  padding-block: 12px;
  padding-inline: 10px;
  margin-top: 2.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.modal1 p {
  font-size: 14px;
  padding-block: 0.5rem;
}
.modal1 p:last-child {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/VehiclesModal/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,uBAAA;EACA,2CAAA;AACJ;AAAI;EACI,eAAA;EACA,qBAAA;AAER;AAAI;EACI,UAAA;AAER","sourcesContent":[".modal1{\n    position: absolute;\n    right: 3rem;\n    border-radius: 12px;\n    padding-block: 12px;\n    padding-inline: 10px;\n    margin-top: 2.5rem;\n    background-color: white;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;\n    p{\n        font-size: 14px;\n        padding-block: .5rem;\n    }\n    p:last-child{\n        color: red;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
