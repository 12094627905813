import { createSlice } from '@reduxjs/toolkit';
import { state } from './state';
import { createChat } from './actionCreator';

export const chatBotSlice = createSlice({
    name: 'chatBot',
    initialState: state,
    reducers: {
        setReturnFromRazorpay: (state, action) => {
            state.returnFromRazorpay = action.payload;
        },
        setMessages: (state, action) => {
            state.messages.push(action.payload)
        },
        resetMessages: (state) => {
            state.messages = []; // Reset messages to an empty array
        },
        setLeoButton: (state, action) => {
            state.leoButton = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createChat.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = '';
            state.isSuccess = true;
            state.chatMessages = action?.payload?.resp?.data?.botMessage;
            state.chatData = action?.payload?.resp?.data;
            state.refundType = action?.payload?.resp?.data?.payload?.payload?.type;


            if (action?.payload?.resp?.data?.method === 'account_topup') {
                state.messages.push({
                    payment: {
                        message: 'Here is your payment link:',
                        amount: state.chatData?.payload?.amount,
                        buttonText: 'Pay Now',
                    },
                    sender: 'bot',
                });
            } else if (action?.payload?.resp?.data?.method === 'general_response') {
                state.messages.push({
                    text: action?.payload?.resp?.data?.botMessage,
                    sender: 'bot',
                })
            }else if (action?.payload?.resp?.data?.method === 'refund') {
                const refundType = action?.payload?.resp?.data?.payload?.payload?.type;
                const refundAmount = action?.payload?.resp?.data?.payload?.payload?.amountRefunded;

                if (refundType === 'other') {
                    state.messages.push({
                        refund: {
                            message: action?.payload?.resp?.data?.message,
                            showSupportBtn: false,
                            refundAmount: 0,
                        },
                        sender: 'bot',
                    });
                } else if (refundType === 'refund_initiated') {
                    state.messages.push({
                        refund: {
                            message: `Your refund request of ₹${refundAmount} has been initiated and will be reflected in your bank account within 2 working days.`,
                            refundAmount,
                            showSupportBtn: false,
                        },
                        sender: 'bot',
                    });
                } else if (refundType === 'refund_initiated_manual_required') {
                    state.messages.push({
                        refund: {
                            message: `Your refund request of ₹${refundAmount} has been initiated and will be reflected in your bank account within 2 working days.`,
                            refundAmount,
                            showSupportBtn: true,
                        },
                        sender: 'bot',
                    });
                } else if (refundType === 'manual_refund_required') {
                    state.messages.push({
                        refund: {
                            message: 'Your refund request has been initiated and will be processed manually.',
                            showSupportBtn: true,
                            refundAmount: 0,
                        },
                        sender: 'bot',
                    });
                }

            } else if (action?.payload?.resp?.data?.method === 'previous_search') {
                if (action?.payload?.resp?.data?.payload?.case === "no_chargers_available") {
                    console.log(action?.payload?.resp?.data?.botMessage, 'no_chargers_available');
                    state.messages.push({
                        text: action?.payload?.resp?.data?.botMessage,
                        sender: 'bot',
                    })
                } else if (action?.payload?.resp?.data?.payload?.case === "charger_available_single_connector") {
                    state.messages.push({
                        text: action?.payload?.resp?.data?.botMessage,
                        sender: 'bot',
                    })
                } else if (action?.payload?.resp?.data?.payload?.case === "no_session_found") {
                    state.messages.push({
                        text: action?.payload?.resp?.data?.botMessage,
                        sender: 'bot',
                    })
                    state.messages.push({
                        searchStation: {
                            searchStation: true
                        }
                        ,
                        sender: 'bot',
                    });
                } else if (action?.payload?.resp?.data?.payload?.case === "multiple_chargers_available") {
                    state.messages.push({
                        text: action?.payload?.resp?.data?.botMessage,
                        sender: 'bot',
                    })
                    state.messages.push({
                        chargingPoints: action?.payload?.resp?.data?.payload?.data?.map(point => ({
                            name: point.charging_point_name,
                            power: point.model?.output_max,
                            price: point.tariff?.value_per_kwh,
                            lastSession: point.charging_sessions?.length > 0
                                ? new Date(new Date(point.charging_sessions[0].createdAt).getTime() + 19800000 - 60000) // UTC+5:30 and subtract 1 min
                                    .toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
                                : null
                        }))

                    })
                }
            } else if (action?.payload?.resp?.data?.method === "list_stations") {
                state.messages.push({
                    text: action?.payload?.resp?.data?.botMessage,
                    sender: 'bot',
                })
                state.messages.push({
                    stations: action?.payload?.resp?.data?.payload?.map(station => ({
                        name: station.station_name,
                        lastSession: '16 min ago',
                    })),
                });

            } else if (action?.payload?.resp?.data?.method === "no_match_station") {
                state.messages.push({
                    text: action?.payload?.resp?.data?.botMessage,
                    sender: 'bot',
                })
                state.messages.push({
                    searchStation: {
                        searchStation: true
                    }
                    ,
                    sender: 'bot',
                });
            } else if (action?.payload?.resp?.data?.method === 'balance') {
                state.messages.push({
                    text: `Your current wallet balance is ₹${action?.payload?.resp?.data?.payload?.walletBalance
                        }`,
                    sender: 'bot',
                });
            } else if (action?.payload?.resp?.data?.method === 'customer_profile') {
                state.messages.push({
                    text: action?.payload?.resp?.data?.botMessage,
                    sender: 'bot',
                })
            }
        });

        builder.addCase(createChat.rejected, (state, action) => {
            console.error('API failed:', action.error);
            state.isLoading = false;
            state.isError = action.error.message || 'Something went wrong';
        });
    },
});

export const { setReturnFromRazorpay, setMessages, resetMessages, setLeoButton } = chatBotSlice.actions;
export default chatBotSlice.reducer;
