// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditProFileView {
  padding-inline: 2rem;
  background-color: white;
  height: 100vh;
}
.EditProFileView .EditProFileView--btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 3rem;
}
.EditProFileView .EditProFileView--btn .back-btn {
  display: flex;
  gap: 2rem;
}
.EditProFileView .EditProFileView--btn .back-btn p {
  align-self: center;
}
.EditProFileView .EditProFileView--btn p {
  font-size: 18px;
  font-weight: 600;
}
.EditProFileView .EditProFileView--content {
  display: flex;
  justify-content: center;
}
.EditProFileView .EditProFileView--content .profile-img {
  padding-block: 3rem;
}
.EditProFileView .EditProFileView--content .profile-img img {
  margin-inline: 15%;
  height: 96px;
  width: 96px;
  border-radius: 100%;
  align-items: center;
}
.EditProFileView .EditProFileView--content .profile-img p {
  padding-block: 0.5rem;
  font-size: 14px;
  color: #00aa74;
}
.EditProFileView .input-group-filed {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/MyProfileView/EditProfileView/editprofileview.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,uBAAA;EACA,aAAA;AACJ;AAAI;EAQI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AALR;AALQ;EACI,aAAA;EACA,SAAA;AAOZ;AANY;EACI,kBAAA;AAQhB;AADQ;EACI,eAAA;EACA,gBAAA;AAGZ;AAAI;EACI,aAAA;EACA,uBAAA;AAER;AADQ;EACI,mBAAA;AAGZ;AAFY;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;AAIhB;AAFY;EACI,qBAAA;EACA,eAAA;EACA,cAAA;AAIhB;AAAI;EACI,aAAA;EACA,sBAAA;EACA,WAAA;AAER","sourcesContent":[".EditProFileView {\n    padding-inline: 2rem;\n    background-color: white;\n    height: 100vh;\n    .EditProFileView--btn {\n        .back-btn {\n            display: flex;\n            gap: 2rem;\n            p {\n                align-self: center;\n            }\n        }\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        padding-block: 3rem;\n        p {\n            font-size: 18px;\n            font-weight: 600;\n        }\n    }\n    .EditProFileView--content {\n        display: flex;\n        justify-content: center;\n        .profile-img {\n            padding-block: 3rem;\n            img {\n                margin-inline: 15%;\n                height: 96px;\n                width: 96px;\n                border-radius: 100%;\n                align-items: center;\n            }\n            p {\n                padding-block: 0.5rem;\n                font-size: 14px;\n                color: #00aa74;\n            }\n        }\n    }\n    .input-group-filed{\n        display: flex;\n        flex-direction: column;\n        gap: 2.5rem;\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
