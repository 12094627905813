import React, { useEffect, useState } from 'react';
import MyProfilelayout from '../../../Layouts/MyProfileLayout/MyProfilelayout';
import './myprofileview.scss';
import Input from '../../../Components/Common/Input/Input';
import ProfileAboutCard from '../../../Components/ProfileCard/ProfileAboutCard';
import LogoutIcon from '../../../Assets/icons/LogoutIcon';
import WarnlingModal from '../../../Components/Common/WarningModal/WarnlingModal';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails } from './redux/profileSlice';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../Router/Pathname';
import { getHomeDetails } from '../HomeView/redux/homeSlice';
import { ReactGA } from "../../../Utils/ga4";
import chatBotIcon from '../../../Assets/icons/floatChatIcon.svg';


const MyProFileView = () => {
    useEffect(() => {
        ReactGA.sendPageView("User profile page");
    }, []);

    const [logout, setLogout] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state);
    const { error, loading, profileInfo } = profile;
    //    const [inputData, setInputData] = useState({
    //        address: profileInfo?.payload?.customer?.address,
    //        email: profileInfo?.payload?.customer?.email,
    //    });
    // const {} = profileInfo

    // console.log('profileInfo', profileInfo);

    useEffect(async () => {
        let data = await dispatch(getHomeDetails());
        //   if (!data?.payload?.payload?.is_onboarded) {
        //         navigate('/userinfo');
        //     }
    }, []);

    const handleopenlogout = () => {
        setLogout(true);
    };
    const handlecloselogout = () => {
        setLogout(false);
    };

    useEffect(() => {
        dispatch(getProfileDetails());
    }, []);

    const handleSubmit = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.open(Pathname.LOGIN, '_self');
    };

    return (
        <div>
            <MyProfilelayout>
                <div className="MyProfileView--userImg">
                    <img
                        src="https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg"
                        alt="user"
                    />
                </div>
                <div className="MyProfileView--content">
                    <div className="text">
                        <p>{profileInfo?.payload?.customer?.user_name}</p>
                        <p>+91{profileInfo?.payload?.customer?.mobile_number}</p>
                    </div>
                    <div className="input-filed">
                        <div className="forEmail">
                            <span>Email</span>
                            <p>{profileInfo?.payload?.customer?.email}</p>
                        </div>
                        <div className="forAddress">
                            <span>Address</span>
                            <p>{profileInfo?.payload?.customer?.address}</p>
                        </div>
                    </div>
                </div>
                <div className="hr-dividerline"></div>
                <ProfileAboutCard />
                <div className="MyProfileView--logout" onClick={handleopenlogout}>
                    <LogoutIcon /> <p style={{ color: '#EC6A5E' }}>Log out</p>
                </div>
                {/* ChatBot Icon */}
                                 <div className="chatBotIcon" onClick={()=>navigate(Pathname.CHAT_BOT)}>
                                            <img src={chatBotIcon} alt="chatboticon"/>
                                        </div>
            </MyProfilelayout>
            <WarnlingModal
                isOpen={logout}
                onClose={handlecloselogout}
                text="Are you sure you want to log out?"
                handleSubmit={handleSubmit}
            />
        </div>
    );
};

export default MyProFileView;
