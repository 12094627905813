// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DownloadInvoice {
  width: 78vw;
}
.DownloadInvoice .invoice-tag {
  margin-top: -2rem;
  font-size: 18px;
}
.DownloadInvoice .invoice {
  display: flex;
  gap: 1rem;
}
.DownloadInvoice .invoice .status-name > p:first-child, .DownloadInvoice .invoice .station-rate > p:first-child {
  color: #8C8C8C;
}
.DownloadInvoice :first-child, .DownloadInvoice :nth-child(2) {
  margin-bottom: 0px;
}
.DownloadInvoice .card {
  background-color: white;
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  padding: 12px;
  width: 50%;
}
.DownloadInvoice .card p {
  padding-block: 0.3rem;
}
.DownloadInvoice .withchild {
  width: 100%;
}

.left > p, .right > p {
  padding-block: 0.3rem;
}

.left > p {
  color: #8C8C8C;
}

.total-amount {
  color: #00AA74;
  font-weight: 700;
}

.download--btn {
  padding-inline: 3rem;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/InvoiceDownload/invoicedownload.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAAI;EACI,iBAAA;EACA,eAAA;AAER;AAAI;EACI,aAAA;EACA,SAAA;AAER;AADQ;EACI,cAAA;AAGZ;AAAI;EACI,kBAAA;AAER;AAAI;EACI,uBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;AAER;AADQ;EACI,qBAAA;AAGZ;AACI;EACI,WAAA;AACR;;AAEA;EACI,qBAAA;AACJ;;AACA;EACI,cAAA;AAEJ;;AAAA;EACI,cAAA;EACA,gBAAA;AAGJ;;AADA;EAEI,oBAAA;EACA,mBAAA;AAGJ","sourcesContent":[".DownloadInvoice{\n    width: 78vw;\n    .invoice-tag{\n        margin-top: -2rem;\n        font-size: 18px;\n    }\n    .invoice{\n        display: flex;\n        gap: 1rem;\n        .status-name>p:first-child,.station-rate>p:first-child{\n            color: #8C8C8C;\n        }\n    }\n    :first-child,:nth-child(2){\n        margin-bottom: 0px;\n    }\n    .card{\n        background-color: white;\n        border: 1px solid #F2F2F2;\n        border-radius: 12px;\n        padding: 12px;\n        width: 50%;\n        p{\n            padding-block: 0.3rem;\n\n        }\n    }\n    .withchild{\n        width: 100%;\n    }\n}\n.left>p,.right>p{\n    padding-block: 0.3rem;\n}\n.left>p{\n    color: #8C8C8C;\n}\n.total-amount{\n    color: #00AA74;\n    font-weight: 700;\n}\n.download--btn{\n    // height: 40px;\n    padding-inline  : 3rem;\n    align-items: center;\n}\n.wallet-modal-close{\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
