// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletView {
  padding-block: 3rem;
}
.WalletView .WalletView--filter-btn {
  display: flex;
  justify-content: space-between;
}
.WalletView .WalletView--filter-btn h1 {
  font-size: 18px;
  font-weight: 600;
}
.WalletView .WalletView--transaction-history {
  padding-block: 1rem;
  margin-bottom: 8rem;
}

.chatBotIcon {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 64px;
  height: 64px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/WalletView/WalletView.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAAI;EACI,aAAA;EACA,8BAAA;AAER;AADQ;EACI,eAAA;EACA,gBAAA;AAGZ;AAAI;EACQ,mBAAA;EACA,mBAAA;AAEZ;;AAEA;EACI,eAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;AACJ","sourcesContent":[".WalletView{\n    padding-block:3rem ;\n    .WalletView--filter-btn{\n        display: flex;\n        justify-content: space-between;\n        h1{\n            font-size: 18px;\n            font-weight: 600;\n        }\n    }\n    .WalletView--transaction-history{\n            padding-block: 1rem;\n            margin-bottom: 8rem;\n    }\n}\n\n.chatBotIcon {\n    position: fixed;\n    bottom: 80px;\n    right: 20px;\n    width: 64px;\n    height: 64px;\n    background: white;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
