// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* UserInfoView.css */
.vehicle-card-container {
  margin: 20px 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.selectable-card {
  border: 1px solid #d9d9d9;
  padding: 24px 30px;
  border-radius: 12px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}

.selectable-card.selectedBox {
  border: 1px solid #00AA74;
}

.wheeler-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  background: #333333;
}`, "",{"version":3,"sources":["webpack://./src/Views/beforeAuth/LoginView/SelectedCard/SelectableCard.scss"],"names":[],"mappings":"AAAA,qBAAA;AAEA;EACI,cAAA;EACA,aAAA;EACA,SAAA;EACA,eAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,sCAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AAAJ;;AAGA;EAEE,yBAAA;AADF;;AAIA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;AADA","sourcesContent":["/* UserInfoView.css */\n\n.vehicle-card-container {\n    margin: 20px 0;\n    display: flex;\n    gap: 20px;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.selectable-card {\n    border: 1px solid #d9d9d9;\n    padding: 24px 30px;\n    border-radius: 12px;\n    display: flex;\n    text-align: center;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    display: flex;\n    flex-direction: column-reverse;\n    gap: 10px;\n}\n\n.selectable-card.selectedBox {\n//    background-color:#e6f7ef; \n  border: 1px solid #00AA74;\n}\n\n.wheeler-title{\nfont-size: 16px;\nfont-weight: 400;\nline-height: 20px;\nletter-spacing: 0em;\nbackground: #333333;\n\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
