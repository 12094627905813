import React from "react";
import Button from "../../../Components/Common/Button/Button";
import { useDispatch } from "react-redux";
import { setTriggerRazorpay } from "../../../Views/AfterAuth/WalletView/redux/walletSlice";
import { useNavigate } from "react-router-dom";
import { Pathname } from "../../../Router/Pathname";

const WalletCard = ({ payment, order_id, returnFromRazorpay }) => {
  if (!payment) {
    return null; // Return nothing if payment data is empty
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddMoney = () => {
    if (returnFromRazorpay) return; // Prevent function execution if disabled
    dispatch(setTriggerRazorpay(true)); // Dispatch event
    navigate(`${Pathname.START_CHARGING}/add-money`, { state: { orderId: order_id, fromChatBot: true } });
  };

  return (
    <div className="payment-container">
      <p className="payment-header">{payment.message}</p>
      <div className="payment-card">
        <p className="payment-title">Top up of</p>
        <h2 className="payment-amount">{payment.amount}</h2>
        <Button 
          text={payment.buttonText} 
          className="payment-button" 
          onClick={handleAddMoney} 
          disabled={returnFromRazorpay} 
        />
      </div>
    </div>
  );
};

export default WalletCard;
