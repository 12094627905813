import React from "react";
import './refundpolicyview.scss'
import ArrowLeft from "../../../../Assets/icons/ArrowLeft";
import { useNavigate } from "react-router-dom";
import { Pathname } from "../../../../Router/Pathname";

const RefundPolicyView = () => {

    const navigate = useNavigate();

    return(
        <main className='RefundPolicyView'>
            <div className="RefundPolicyView-navigate">
                <span onClick={() => navigate(`${Pathname.PROFILE}`)}>
                    <ArrowLeft />
                </span>
                <p>Refund Policy</p>
            </div>
            <div className="RefundPolicyView-content">
                <p>
                    If, for any reason, you are not completely satisfied 
                    with a purchase/availing service, we invite you to 
                    review our policy on refunds and returns.
                </p>
                <p>
                    The following terms are applicable for any products
                    or service that you purchased with us. 
                </p>
                <div className="RefundPolicyView-title">
                    <strong>CHARGING SERVICE</strong>
                </div>
                <p>
                    <strong>USER REGISTRATION</strong> 
                </p>
                <p>
                    The person who is using the service should be registered 
                    to the system as a user/ customer. For registering as a 
                    user/ customer the person who uses the service should 
                    logon to www.user.lioncharge.tech from the browser and 
                    register themselves as user/ customer by providing 
                    personal information such as phone number, email & name. 
                    The user can use their phone number for registering 
                    purpose. Additional personal information might be required 
                    after registration subject to services requested by the 
                    user/ customer. 
                </p>
                <p>
                    <strong>CONNECTION TO THE SERVICE</strong>
                </p>
                <p>
                    The user can access the charging points with the help of 
                    lioncharge platform. 
                </p>
                <p>
                    <strong>CHARGING SESSION</strong>
                </p>
                <p>
                    The charging session starts from the time when the user 
                    starts the session through browser-app/ RFID Key & the 
                    session is considered ended when the timer runs out for 
                    the session. Also, the session is considered ended if 
                    the user/ customer terminates the session manually from 
                    the App/ RFID Key It is the customer’s responsibility 
                    to make sure the charging begins & completes correctly. 
                </p>
                <p>
                    <strong>PROCEDURE FOR REQUESTING REFUND </strong>
                </p>
                <p>
                    In case where the user/ customer needs a refund, it can 
                    be raised through sending an email to support@lioncharge.
                    tech with the reason for refund of fee. Refunds will be 
                    processed only for requests submitted within 72 hours 
                    of the booking. Refund requests raised after 72 hours 
                    of the booking date shall not be eligible for refunds. 
                    The approval/ settlement of the refund requests can 
                    take up to 5 to 7 business days.
                </p>
                <p>
                    <strong>ELIGIBILITY CRITERIA FOR REFUNDS </strong>
                </p>
                <div className="RefundPolicyView-criteria">
                    <ul>
                        <li>
                            The device is unable to provide output even when 
                            there is power supply.
                        </li>
                        <li>
                            The device is inaccessible due to any software/ 
                            firmware related issue from the LionCharge team.
                        </li>
                        <li>
                            The device stopped abruptly due to some technical 
                            issues. Here, in cases where there are Energy 
                            consumption values, partial refund can be done 
                            based on the usage.
                        </li>
                        <li>
                            In cases where the payment was successful & the 
                            user was unable to go to the booking page, the 
                            transaction id shall be collected from the user 
                            & cross verify the same with the concerned team 
                            internally. If found eligible, refund shall be 
                            processed for the user.
                        </li>
                    </ul>
                </div>
                    <p>
                        For any other cases, in cases where the payment has 
                        failed, refunds shall not be considered as the user 
                        will be refunded automatically within 7 business 
                        days by the payment gateway itself. 
                    </p>
                    <p>
                        Any failure/ error happening during the transaction 
                        time can be from both parties (LionCharge team & the 
                        Payment Gateway). In these cases, transaction id 
                        shall be collected from the user & cross verify the 
                        same with the concerned team internally. If found 
                        eligible, refund shall be processed for the user. 
                    </p>
                    <div className="RefundPolicyView-title">
                        <strong>PRODUCT</strong>
                    </div>
                    <p>
                        <strong>DEFINITIONS </strong>
                    </p>
                    <p>
                        For the purposes of this Refund Policy: 
                    </p>
                    <p>
                        <b>Company</b> (referred to as either "the Company",
                        "We", "Us" or "Our" in this Agreement) refers to 
                        LionCharge E-Mobility Pvt Ltd, Plot no 171, road no 
                        13, Jubilee Hills, Hyderabad, India. 
                    </p>
                    <p>
                        <b>Wallet</b> refers to the pre-paid service to 
                        charge your EV within the facility using our service. 
                    </p>
                    <p>
                        <b>Service</b> refers to the Website.
                    </p>
                    <p>
                        <b>Website</b> refers to LionCharge , accessible from 
                        www.lioncharge.tech 
                    </p>
                    <p>
                        <b>You</b> means the individual accessing or using 
                        the Service, or the company, or other legal entity on 
                        behalf of which such individual is accessing or using 
                        the Service, as applicable.
                    </p>
                    <div className="RefundPolicyView-title">
                        <strong>YOUR REFUND RIGHTS </strong>
                    </div>
                    <p>
                        You are entitled to request a wallet refund. 
                    </p>
                    <p>
                        In order to exercise your right of refund, you must 
                        inform us of your decision by means of a clear 
                        statement. You can inform us of your decision by:
                    </p>
                    <p>
                        By email: support@lioncharge.tech 
                    </p>
                    <p>
                        By phone number: +91 9154209259 
                    </p>
                    <p>
                        <strong>CONTACT US</strong>
                    </p>
                    <p>
                        If you have any questions about Refunds Policy, 
                        please contact us: 
                    </p>
                    <p>
                        By email: support@lioncharge.tech
                    </p>
                    <p>
                        By phone number: +91 9154209259
                    </p>
            </div>
        </main>
    )
}

export default RefundPolicyView;