import React from 'react';
import './LeoButton.scss';
import sendIcon from '../../../Assets/icons/SendIcon.svg';
import InputIcon from '../../../Assets/icons/input-bot-icon.svg';

const LeoButton = ({ text = 'Need help with charging', onClick }) => {
    return (
        <button className="leo-button" onClick={onClick}>
            <div className="leo-button-wrapper">
                <div className='leo-button-inner'>
                    <span className="icon">
                        <img src={InputIcon} alt="input-icon" />
                    </span>
                    <span className="text">{text}</span>
                </div>
                <div>
                    <span className="arrow">
                        <img src={sendIcon} alt="Send-icon" />
                    </span>
                </div>
            </div>
        </button>
    );
};

export default LeoButton;
