import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Pathname } from '../Pathname';
import {
    EMSP,
    HelpAndSupport,
    Home,
    Login,
    MyVehicles,
    Profile,
    SplashScreen,
    Wallet,
    UserInfo,
} from '../../Pages';
import { ErrorBoundary } from '../../Components';
import Components from '../../Views/beforeAuth/Components/Components';
import TermsAndCondn from '../../Views/beforeAuth/Terms and Conditons/TermsAndCondn';
import PrivacyPolicy from '../../Views/beforeAuth/LoginView/PrivacyPolicy/PrivacyPolicy';
import SelectView from '../../Views/AfterAuth/StartChargeingView/SelectConnectionView/SelectView';
import StartCharginhView from '../../Views/AfterAuth/StartChargeingView/StartCharginhView';
import IntiatechargingView from '../../Views/AfterAuth/StartChargeingView/IntiateChargingView/IntiatechargingView';
import AddMoney from '../../Views/AfterAuth/StartChargeingView/AddMoney/AddMoney';
import StartCharging from '../../Views/AfterAuth/StartChargeingView/StartCharging/StartCharging';
import AddVehiclesView from '../../Views/AfterAuth/MyVehiclesView/AddVehiclesView/AddVehiclesView';
import EditProFileView from '../../Views/AfterAuth/MyProfileView/EditProfileView/EditProFileView';
import HelpandSupportView from '../../Views/AfterAuth/MyProfileView/HelpandSupportView/HelpandSupportView';
import AboutView from '../../Views/AfterAuth/MyProfileView/AboutView/AboutView';
import ProtectedRoute from '../PrivateRoute/ProtectedRoute';
import EnergyCalculation from '../../Views/AfterAuth/StartChargeingView/EnergyCalculations/EnergyCalculation';
import SearchStations from '../../Pages/SearchStations/SearchStations';
import SearchConnector from '../../Views/AfterAuth/SearchStationsView/SearchConnectors/SearchConnectors';
import RefundPolicyView from '../../Views/AfterAuth/MyProfileView/RefundPolicyView/RefundPolicyView';
import ChatBot from '../../Pages/ChatBot/ChatBot';

const router = createBrowserRouter([
    {
        path: Pathname.SPLASH_SCREEN,
        element: <SplashScreen />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.LOGIN,
        element: (
            <ProtectedRoute isProtected={false}>
                <Login />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.USERINFO,
        element: (
            <ProtectedRoute isProtected={false}>
                <UserInfo />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.HOME,
        element: (
            <ProtectedRoute isProtected={true}>
                <Home />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.EMSP,
        element: <EMSP />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.START_CHARGING, // scan qr
        errorElement: <ErrorBoundary />,
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute isProtected={true}>
                        <StartCharginhView />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'select-connection',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <SelectView />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'intiate-charging',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <IntiatechargingView />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'add-money',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <AddMoney />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'start-charging',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <StartCharging />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'start-charging',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <StartCharging />
                    </ProtectedRoute>
                ),
            },
            {
                path: Pathname.ENERGY_BASED,
                element: (
                    <ProtectedRoute isProtected={true}>
                        <EnergyCalculation />
                    </ProtectedRoute>
                ),
            },
        ],
    },
    {
        path: Pathname.HELP_AND_SUPPORT,
        element: (
            <ProtectedRoute isProtected={true}>
                <HelpAndSupport />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.MY_VEHICLES,
        errorElement: <ErrorBoundary />,
        children: [
            {
                index: true,
                element: <MyVehicles />,
            },
            {
                path: 'add-vehicle',
                element: <AddVehiclesView />,
            },
        ],
    },
    {
        path: Pathname.PROFILE,
        errorElement: <ErrorBoundary />,
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute isProtected={true}>
                        <Profile />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'edit-profile',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <EditProFileView />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'help-and-support',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <HelpandSupportView />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'about',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <AboutView />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'privacy-policy',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <PrivacyPolicy />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'refund-policy',
                element: (
                    <ProtectedRoute isProtected={true}>
                        <RefundPolicyView />
                    </ProtectedRoute>
                ),
            },
        ],
    },
    {
        path: Pathname.SEARCH_STATIONS,
        errorElement: <ErrorBoundary />,
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute isProtected={true}>
                        <SearchStations />
                    </ProtectedRoute>
                ),
            },
            {
                path: Pathname.SEARCH_CONNECTOR,
                element: (
                    <ProtectedRoute isProtected={true}>
                        <SearchConnector />
                    </ProtectedRoute>
                ),
            },
            {
                path: Pathname.ENERGY_CALCULATION,
                element: (
                    <ProtectedRoute isProtected={true}>
                        <EnergyCalculation />
                    </ProtectedRoute>
                ),
            },
        ],
    },
    {
        path: Pathname.WALLET,
        element: (
            <ProtectedRoute isProtected={true}>
                <Wallet />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.COMPONENTS,
        element: (
            <ProtectedRoute isProtected={true}>
                <Components />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },

    {
        path: Pathname.TERMS,
        element: (
            <ProtectedRoute isProtected={false}>
                <TermsAndCondn />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.PRIVACY,
        element: (
            <ProtectedRoute isProtected={false}>
                <PrivacyPolicy />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: Pathname.CHAT_BOT,
        element: (
            <ProtectedRoute isProtected={false}>
                <ChatBot />
            </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
    },
]);
export default router;
