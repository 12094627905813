import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Common/Button/Button";
import { Pathname } from "../../../Router/Pathname";

const SearchStationCard = ({ searchStation }) => {
  const navigate = useNavigate();

  return (
    <div className="payment-container">
      {searchStation && <Button text={'Search Stations'} variant="chatButton" className="payment-button" onClick={()=>navigate(Pathname.SEARCH_STATIONS, { state: { fromSearchStationCard: true } })}/>}
    </div>
  );
};

export default SearchStationCard;
