// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular-progress-container {
  position: relative;
}

.progress-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doge-image {
  position: relative;
  bottom: 45px;
  width: 32px;
  height: 32px;
}

.mate-text {
  position: relative;
  top: 15px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
}

/* Add shadow to the circular path */
.circular-progress-container .CircularProgressbar .CircularProgressbar-path {
  filter: drop-shadow(0px 1px 1.5px #00aa74);
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/CircularProgressBar/CircularProgress.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAA;AAAJ;;AAEI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACR;;AAEI;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AACR;;AAEI;EACI,kBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACR;;AAEI,oCAAA;AACA;EACI,0CAAA;AACR","sourcesContent":[".circular-progress-container {\n    // width: 70%;\n    position: relative;\n}\n    .progress-content {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .doge-image {\n        position: relative;\n        bottom: 45px;\n        width: 32px;\n        height: 32px;\n    }\n\n    .mate-text {\n        position: relative;\n        top: 15px;\n        font-size: 14px;\n        font-weight: 300;\n        line-height: 18px;\n        letter-spacing: 0em;\n    }\n\n    /* Add shadow to the circular path */\n    .circular-progress-container .CircularProgressbar .CircularProgressbar-path {\n        filter: drop-shadow(0px 1px 1.5px #00aa74);\n    }\n// }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
