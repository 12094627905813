export const state = {
    isLoading: false,
    isError: true,
    chatMessages:'',
    chatData:{},
    returnFromRazorpay: false,
    messages: [],
    refundType:'',
    leoButton: "",
};
