// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DownloadInvoice {
  width: 78vw;
}
.DownloadInvoice .invoice-tag {
  margin-top: -2rem;
  font-size: 18px;
}
.DownloadInvoice .invoice {
  display: flex;
  gap: 1rem;
}
.DownloadInvoice .invoice .status-name > p:first-child,
.DownloadInvoice .invoice .station-rate > p:first-child {
  color: #8C8C8C;
}
.DownloadInvoice :first-child,
.DownloadInvoice :nth-child(2) {
  margin-bottom: 0px;
}
.DownloadInvoice .card {
  background-color: white;
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  padding: 12px;
  width: 50%;
}
.DownloadInvoice .card p {
  padding-block: 0.3rem;
}
.DownloadInvoice .withchild {
  width: 100%;
}

.left > p,
.right > p {
  padding-block: 0.3rem;
}

.left > p {
  color: #8C8C8C;
}

.total-amount {
  color: #00AA74;
  font-weight: 700;
}

.download--btn {
  padding-inline: 3rem;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/RefundInvoiceDownload/RefundInvoiceDownload.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AACI;EACI,iBAAA;EACA,eAAA;AACR;AAEI;EACI,aAAA;EACA,SAAA;AAAR;AAEQ;;EAEI,cAAA;AAAZ;AAII;;EAEI,kBAAA;AAFR;AAKI;EACI,uBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;AAHR;AAKQ;EACI,qBAAA;AAHZ;AAQI;EACI,WAAA;AANR;;AAUA;;EAEI,qBAAA;AAPJ;;AAUA;EACI,cAAA;AAPJ;;AAUA;EACI,cAAA;EACA,gBAAA;AAPJ;;AAUA;EAEI,oBAAA;EACA,mBAAA;AARJ","sourcesContent":[".DownloadInvoice {\n    width: 78vw;\n\n    .invoice-tag {\n        margin-top: -2rem;\n        font-size: 18px;\n    }\n\n    .invoice {\n        display: flex;\n        gap: 1rem;\n\n        .status-name>p:first-child,\n        .station-rate>p:first-child {\n            color: #8C8C8C;\n        }\n    }\n\n    :first-child,\n    :nth-child(2) {\n        margin-bottom: 0px;\n    }\n\n    .card {\n        background-color: white;\n        border: 1px solid #F2F2F2;\n        border-radius: 12px;\n        padding: 12px;\n        width: 50%;\n\n        p {\n            padding-block: 0.3rem;\n\n        }\n    }\n\n    .withchild {\n        width: 100%;\n    }\n}\n\n.left>p,\n.right>p {\n    padding-block: 0.3rem;\n}\n\n.left>p {\n    color: #8C8C8C;\n}\n\n.total-amount {\n    color: #00AA74;\n    font-weight: 700;\n}\n\n.download--btn {\n    // height: 40px;\n    padding-inline: 3rem;\n    align-items: center;\n}\n\n.wallet-modal-close {}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
