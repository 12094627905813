import React from 'react';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from '../../../../Router/Pathname';

const StationHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleBackNavigation = () => {
        if (location.state?.fromSearchStationCard) {
            navigate(Pathname.CHAT_BOT);
        } else {
            navigate(Pathname.START_CHARGING);
        }
    };
    return (
        <section className="SearchStations__Header">
            <div
                className="SearchStations__Left"
                onClick={handleBackNavigation}
            >
                <ArrowLeft />
            </div>
            <div className="SearchStations__Right">
                <div className="SearchStations__Title">Search Stations</div>
            </div>
        </section>
    );
};

export default StationHeader;
