// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiniConnectorCard {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 12px;
  align-items: center;
  border-radius: 12px;
  gap: 8px;
  border: 1px solid var(--neutral-7, #f2f2f2);
}
.MiniConnectorCard--disabled {
  background-color: #f2f2f2;
}
.MiniConnectorCard__LeftContent {
  display: flex;
  align-items: center;
  gap: 12px;
}
.MiniConnectorCard__Texts {
  display: flex;
  flex-direction: column;
}
.MiniConnectorCard__RightContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.MiniConnectorCard__Badges {
  margin-bottom: 12px;
}
.MiniConnectorCard__Title {
  color: var(--neutral-1, #1e1e1e);
  font-family: Outfit;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.MiniConnectorCard__SubTitle {
  overflow: hidden;
  color: #333;
  text-overflow: ellipsis;
  font-family: Outfit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.MiniConnectorCard__Img {
  width: 68px;
  height: 56px;
}`, "",{"version":3,"sources":["webpack://./src/Components/MiniConnectorCard/MiniConnectorCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,4BAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;EACA,2CAAA;AACJ;AACI;EACI,yBAAA;AACR;AACI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACR;AACI;EACI,aAAA;EACA,sBAAA;AACR;AACI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACR;AAEI;EACI,mBAAA;AAAR;AAEI;EACI,gCAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAEI;EACI,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAEI;EACI,WAAA;EACA,YAAA;AAAR","sourcesContent":[".MiniConnectorCard {\n    display: flex;\n    justify-content: space-between;\n    padding: 16px 16px 16px 12px;\n    align-items: center;\n    border-radius: 12px;\n    gap: 8px;\n    border: 1px solid var(--neutral-7, #f2f2f2);\n    // height: 95px;\n    &--disabled {\n        background-color: #f2f2f2;\n    }\n    &__LeftContent {\n        display: flex;\n        align-items: center;\n        gap: 12px;\n    }\n    &__Texts {\n        display: flex;\n        flex-direction: column;\n    }\n    &__RightContent {\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n    }\n\n    &__Badges {\n        margin-bottom: 12px;\n    }\n    &__Title {\n        color: var(--neutral-1, #1e1e1e);\n        font-family: Outfit;\n        font-size: 1.4rem;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n    }\n    &__SubTitle {\n        overflow: hidden;\n        color: #333;\n        text-overflow: ellipsis;\n        font-family: Outfit;\n        font-size: 1.2rem;\n        font-style: normal;\n        font-weight: 300;\n        line-height: normal;\n    }\n    &__Img {\n        width: 68px;\n        height: 56px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
