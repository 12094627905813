// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo-container {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.frame-container {
  position: relative;
  width: 100%;
  max-width: 360px;
  z-index: 1; /* Lower z-index to have the frame beneath the logo */
}

@media (max-width: 768px) {
  .frame-container {
    max-width: 100%; /* Take full width on medium screens */
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Header/Header.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,UAAA,EAAA,qDAAA;AACJ;;AAEA;EACI;IACI,eAAA,EAAA,sCAAA;EACN;AACF","sourcesContent":[".Header {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.logo-container {\n    position: absolute;\n    top: 100%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 2;\n}\n\n.frame-container {\n    position: relative;\n    width: 100%;\n    max-width: 360px;\n    z-index: 1; /* Lower z-index to have the frame beneath the logo */\n}\n\n@media (max-width: 768px) {\n    .frame-container {\n        max-width: 100%; /* Take full width on medium screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
