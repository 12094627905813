// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maincard {
  display: flex;
  justify-content: space-between;
}
.maincard .right-card {
  text-align: end;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/DividerCard/dividercard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;AAAI;EACI,eAAA;AAER","sourcesContent":[".maincard{\n    display: flex;\n    justify-content: space-between;\n    .right-card{\n        text-align: end;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
