// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutView {
  height: 100vh;
  background-color: white;
}
.AboutView .SubAboutView {
  padding-inline: 2rem;
}
.AboutView .AboutView--navigate {
  display: flex;
  gap: 2rem;
  padding-block: 24px;
  font-weight: 600;
}
.AboutView .AboutView--navigate p {
  font-size: 18px;
  align-self: center;
}
.AboutView .AboutView--content {
  padding-block: 5rem;
  text-align: center;
}
.AboutView .AboutView--content span {
  display: block;
  padding-block: 2rem;
}
.AboutView .AboutView--content p:first-child {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  padding-inline: 6rem;
}
.AboutView .AboutView--content p:last-child {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.12px;
}
.AboutView .AboutView--bgconver {
  position: absolute;
  bottom: 0px;
}
.AboutView .AboutView--bgconver img {
  width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/MyProfileView/AboutView/aboutview.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,uBAAA;AAAJ;AACI;EACI,oBAAA;AACR;AAEI;EAEI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;AADR;AAEQ;EACI,eAAA;EACA,kBAAA;AAAZ;AAGI;EACI,mBAAA;EACA,kBAAA;AADR;AAEQ;EACI,cAAA;EACA,mBAAA;AAAZ;AAEQ;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,oBAAA;AAAZ;AAEQ;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;AAAZ;AAGI;EACI,kBAAA;EACA,WAAA;AADR;AAEQ;EACI,YAAA;AAAZ","sourcesContent":["\n.AboutView{\n    height: 100vh;\n    background-color: white;\n    .SubAboutView{\n        padding-inline: 2rem;\n\n    }\n    .AboutView--navigate{\n    \n        display: flex;\n        gap: 2rem;\n        padding-block: 24px;\n        font-weight: 600;\n        p{\n            font-size: 18px;\n            align-self: center;\n        }\n    }\n    .AboutView--content{\n        padding-block: 5rem;\n        text-align: center;\n        span{\n            display: block;\n            padding-block: 2rem;\n        }\n        p:first-child{\n            font-size: 18px;\n            line-height: 18px;\n            font-weight: 600;\n            padding-inline: 6rem;\n        }\n        p:last-child{\n            color: #333333;\n            font-size: 12px;\n            font-weight: 400;\n            line-height: 15.12px;\n        }\n    }\n    .AboutView--bgconver{\n        position: absolute;\n        bottom: 0px ;\n        img{\n            width: 100vw;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
