// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 1rem;
  padding: 1rem;
}
.Card .Modal {
  width: 152px;
  height: 168px;
  border-radius: 20px;
  background-color: #E6F7EF;
  display: flex;
  flex-direction: column;
}
.Card .icon {
  align-self: end;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Card/Card.scss"],"names":[],"mappings":"AAAA;EACI,2CAAA;EACA,mBAAA;EACA,aAAA;AACJ;AACA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;AACF;AAGA;EACI,eAAA;AADJ","sourcesContent":[".Card {\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n    border-radius: 1rem;\n    padding: 1rem;\n\n.Modal {\n  width: 152px;\n  height: 168px;\n  border-radius: 20px;\n  background-color: #E6F7EF;\n  display: flex;\n  flex-direction: column;\n}\n\n\n.icon{\n    align-self: end;\n}\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
