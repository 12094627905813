import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LargeLogo from '../../Assets/icons/LargeLogo';
import CloseIcon from '../../Assets/icons/closeIcon.svg';
import copyIcon from '../../Assets/icons/copy.svg';
import dislikeIcon from '../../Assets/icons/dislike.svg';
import InputIcon from '../../Assets/icons/input-bot-icon.svg';
import likeIcon from '../../Assets/icons/like.svg';
import MenuIcon from '../../Assets/icons/menuIcon.svg';
import sendIcon from '../../Assets/icons/SendIcon.svg';
import chargingIcon from '../../Assets/icons/wireless-charging.svg';
import faqIcon from '../../Assets/icons/information.svg';
import walletIcon from '../../Assets/icons/down-payment.svg';
import { razorpayStatusRefresh } from '../../Views/AfterAuth/WalletView/redux/walletSlice';
import ChargingPointCard from './ChargingPointCard/ChargingPointCard';
import './ChatBot.scss';
import { createChat } from './Redux/actionCreator';
import {
    resetMessages,
    setLeoButton,
    setMessages,
    setReturnFromRazorpay,
} from './Redux/reducer';
import RefundCard from './RefundCard/RefundCard';
import SelectedStationCard from './SelectedStationCard/SelectedStationCard';
import Sidebar from './Sidebar/Sidebar';
import StationsCard from './StationsCard/StationsCard';
import WalletCard from './WalletCard/WalletCard';
import { generateFakeUUID, getTimeOfDay } from './constants';
import { getProfileDetails } from '../../Views/AfterAuth/MyProfileView/redux/profileSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Autoplay } from 'swiper/modules';
import SearchStationCard from './SearchStationCard/SearchStationCard';
import { Pathname } from '../../Router/Pathname';

const options = ['Top up my Account', 'what is my current balance?', 'Initiate Refund'];
const chargerOptions = [' How many charge points are available?','Charging metrics for this month'];


const ChatBot = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    //Data from API
    const { chatData, returnFromRazorpay, messages, leoButton } = useSelector(
        (store) => store.chatBot
    );
    const { profile } = useSelector((state) => state);
    const { profileInfo } = profile;

    let order_id = chatData?.payload?.order_id ?? '';

    //State to manage selected station
    const [selectedStation, setSelectedStation] = useState({});

    //State to manage input field
    const [inputValue, setInputValue] = useState('');

    //State to manage chat history sidebar
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    // Generate and store the session ID only once
    const sessionId = useRef(generateFakeUUID());

    //Function for on change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    //Order Status API
    useEffect(() => {
        if (returnFromRazorpay) {
            dispatch(razorpayStatusRefresh(order_id))
                .unwrap()
                .then((res) => {
                    if (res && res.payload?.paymentStatus) {
                        dispatch(
                            setMessages({
                                text: `Your payment of ₹${res.payload?.tran?.amount} has been ${res.payload?.paymentStatus}!`,
                                sender: 'bot',
                            })
                        );
                    }
                })
                .catch((error) => {
                    console.error('Error refreshing Razorpay status:', error);
                });
            dispatch(setReturnFromRazorpay(false)); // Reset state after API call
        }
    }, [returnFromRazorpay]);

    //Function for sending messages
    const handleSendMessage = (e) => {
        e.preventDefault();
        if (!inputValue.trim()) return;

        if (chatData?.payload?.case === 'charger_available_single_connector') {
            dispatch(
                createChat({
                    body: {
                        session_id: sessionId.current,
                        // chat_input: `${inputValue} connector : ${chatData?.payload?.data?.map((item)=>item.id)}`,
                        chat_input: `${inputValue} connector : 1`,
                        bot_persona: leoButton,
                    },
                })
            );
        } else {
            dispatch(
                createChat({
                    body: {
                        session_id: sessionId.current,
                        chat_input: inputValue,
                        bot_persona: leoButton,
                    },
                })
            );
        }
        const userMessage = { text: inputValue, sender: 'user' };
        dispatch(setMessages(userMessage));
        setInputValue('');
    };

    // Function to Select Stations
    const handleSelectStation = (station) => {
        setSelectedStation(station);
        // Move the selected station to user messages
        dispatch(setMessages({ sender: 'user', station }));
        dispatch(
            createChat({
                body: {
                    session_id: sessionId.current,
                    chat_input: 'sagar cements limited',
                    bot_persona: leoButton,
                },
            })
        );
    };

    useEffect(() => {
        dispatch(getProfileDetails());
    }, []);

    const handleResetMessages = () => {
        dispatch(resetMessages()); // Resets the messages state
            if (leoButton) {
                dispatch(setLeoButton('')); // Hide the Swiper
            } else {
                navigate(Pathname.HOME); // Navigate to Home
            }        
    };

    const handleOptionClick = (option) => {
        // Dispatch the selected option as a user message
        dispatch(setMessages({ text: option, sender: 'user' }));
        // Call the API to send the message
        dispatch(
            createChat({
                body: {
                    session_id: sessionId.current,
                    chat_input: option,
                    bot_persona: leoButton,
                },
            })
        );
    };

    return (
        <div className="chat-container">
            <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
            <div className="chat-box">
                <div className="chat-header">
                    <div onClick={() => setIsSidebarOpen(true)}>
                        <img src={MenuIcon} alt="menu-icon" />
                    </div>
                    <p>LEO</p>
                    <div onClick={handleResetMessages}>
                        <img src={CloseIcon} alt="close-icon" />
                    </div>
                </div>

                {leoButton == '' && (
                    <div className="chat-avatar-wrapper">
                        <div className="chat-avatar">
                            <LargeLogo />
                            <h3 className="chat-greeting">
                                {' '}
                                {getTimeOfDay()},{' '}
                                {profileInfo?.payload?.customer?.user_name || 'Guest'} 👋
                            </h3>
                            <p className="chat-description">
                                Hi! I'm Leo, your EV charging companion. How may I assist?
                            </p>
                        </div>
                    </div>
                )}

                {leoButton == 'payment_assistant' && messages.length === 0 && (
                    <div className="chat-avatar-wrapper">
                        <div className="chat-avatar">
                            <LargeLogo />
                            <h3 className="chat-greeting">
                                {' '}
                                {getTimeOfDay()},{' '}
                                {profileInfo?.payload?.customer?.user_name || 'Guest'} 👋
                            </h3>
                            <p className="chat-description">
                                Hi, I can help with payments and initiate refunds.
                            </p>
                        </div>
                    </div>
                )}
                {leoButton == 'charging_assistant' && messages.length === 0 && (
                    <div className="chat-avatar-wrapper">
                        <div className="chat-avatar">
                            <LargeLogo />
                            <h3 className="chat-greeting">
                                {' '}
                                {getTimeOfDay()},{' '}
                                {profileInfo?.payload?.customer?.user_name || 'Guest'} 👋
                            </h3>
                            <p className="chat-description">
                            Hi, I can help with checking the available charge points in your building
                            </p>
                        </div>
                    </div>
                )}

                <div className="chat-messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={`chat-message ${msg.sender}`}>
                            {msg.text && <p>{msg.text}</p>}
                            {/*Adding into Wallet Balance */}
                            {msg.payment && (
                                <WalletCard
                                    payment={msg.payment}
                                    order_id={chatData?.payload?.order_id}
                                    returnFromRazorpay={returnFromRazorpay}
                                />
                            )}
                            {/*Refund */}
                            {msg.refund && <RefundCard refund={msg.refund} />}
                            {/* Render selected station inside user message */}
                            {msg.station && (
                                <SelectedStationCard
                                    station={msg.station}
                                    sender={msg.sender}
                                />
                            )}
                            {/* Render stations if the message contains stations */}
                            {msg.stations && (
                                <StationsCard
                                    stations={msg.stations}
                                    handleSelectStation={handleSelectStation}
                                />
                            )}

                            {/* Render charging points after selecting station */}
                            {msg.chargingPoints && (
                                <ChargingPointCard chargingPoints={msg.chargingPoints} />
                            )}

                            {msg.searchStation && (
                                <SearchStationCard searchStation={msg.searchStation} />
                            )}

                            {msg.sender === 'bot' && !msg.searchStation && (
                                <div className="chat-actions">
                                    <img src={likeIcon} alt="like-icon" />
                                    <img src={dislikeIcon} alt="dislike-icon" />
                                    <img
                                        src={copyIcon}
                                        alt="copy-icon"
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                msg.text ||
                                                    msg.payment?.message ||
                                                    msg.refund?.message
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    {leoButton !==''&& messages.length=== 0 && (
                        <div className="swiper-container">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={leoButton === 'payment_assistant' ? 2 : 1}
                                freeMode={true}
                                modules={[FreeMode, Autoplay]}
                                className="mySwiper"
                                autoplay={{ delay: 2000, disableOnInteraction: false }}
                            >
                                {(leoButton === 'payment_assistant' ? options : chargerOptions).map((option, index) => (
                                    <SwiperSlide
                                        key={index}
                                        style={{
                                            width: '100%',
                                            minWidth: '220px',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: '500',
                                        }}
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        {option}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )}

                    {leoButton !== '' && (
                        <form
                            onSubmit={handleSendMessage}
                            className="chat-input-container"
                        >
                            <img
                                src={InputIcon}
                                alt="input-icon"
                                className="input-icon"
                            />
                            <input
                                type="text"
                                placeholder="Ask anything"
                                className="chat-input"
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                            {inputValue.trim() && (
                                <img
                                    src={sendIcon}
                                    alt="Send-icon"
                                    className="send-btn"
                                    onClick={handleSendMessage}
                                />
                            )}
                        </form>
                    )}
                </div>

                {/*leo Button wrapper */}
                {leoButton == '' && (
                    <div className="bot-button-wrapper">
                        <div className="bot-button">
                            {/* Heading shown only once */}
                            <p className="button-heading">Choose below Leo Agent</p>

                            <div
                                className="button-card"
                                onClick={() =>
                                    dispatch(setLeoButton('charging_assistant'))
                                }
                            >
                                <img
                                    src={chargingIcon}
                                    alt={'chargingPoint.name'}
                                    className="bot-button-image"
                                />
                                <div className="station-details">
                                    <p className="bot-button-name">Charging Available Assistant</p>
                                </div>
                            </div>
                            <div
                                className="button-card"
                                onClick={() =>
                                    dispatch(setLeoButton('payment_assistant'))
                                }
                            >
                                <img
                                    src={walletIcon}
                                    alt={'chargingPoint.name'}
                                    className="bot-button-image"
                                />
                                <div className="station-details">
                                    <p className="bot-button-name">Payment Assistant</p>
                                </div>
                            </div>
                            <div className="button-card">
                                <img
                                    src={faqIcon}
                                    alt={'chargingPoint.name'}
                                    className="bot-button-image"
                                />
                                <div className="station-details">
                                    <p className="bot-button-name">FAQ and Support Assistant</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatBot;
