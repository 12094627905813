import React, { useEffect, useState } from 'react';
import './addmoney.scss';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import { Button } from '../../../../Components/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from '../../../../Router/Pathname';
import Input from '../../../../Components/Common/Input/Input';
import AddMoneySuggests from '../../../../Components/Common/AddMoneySuggests/AddMoneySuggests';
import ModalSucessProgress from '../../../../Components/Common/ModalSucessProgress/ModalSucessProgress';
import Payment from '../../../../Components/RazorPay/Payment';
import { useDispatch, useSelector } from 'react-redux';
import {
    addMoney,
    getWalletTransactions,
    razorpayStatusInfo,
    resetWalletTransactions,
    setTriggerRazorpay,
} from '../../WalletView/redux/walletSlice';
import { getProfileDetails } from '../../MyProfileView/redux/profileSlice';
import { ReactGA } from '../../../../Utils/ga4';
import { setReturnFromRazorpay } from '../../../../Pages/ChatBot/Redux/reducer';

const AddMoney = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const razorKey = process.env.razorpaykey;
    // const razorKey = 'rzp_live_HXUpsXHt4FXWPC';
    const location = useLocation(); 
    const [data, setData] = useState();
    const [amount, setAmount] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [inputData, setInputData] = useState('');
    const { profile } = useSelector((state) => state);
    const { error, loading, profileInfo } = profile;


    const {triggerRazorpay} = useSelector((state) => state.wallet);
    const fromChatBot = location.state?.fromChatBot || false;

    React.useEffect(() => {
        ReactGA.sendPageView('Add money page');
    }, []);

    
    useEffect(() => {
        if (triggerRazorpay) {
            console.log("Opening Razorpay Modal...");
            openPayModal(); // Call Razorpay function
            dispatch(setTriggerRazorpay(false)); // Reset the trigger state
        }
    }, [triggerRazorpay, dispatch]); 

    const sumValue = Number(amount) + Number(inputData);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const handledata = (data) => {
        setData(data);
    };

    useEffect(() => {
        dispatch(getProfileDetails());
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);
    // const openPayModal = async () => {
    //     // Fetch to get order details
    //     dispatch(addMoney({ amount: sumValue }))
    //         .then((data) => {
    //             const options = {
    //                 key: razorKey,
    //                 order_id:fromChatBot?data?.payload?.payload?.order_id :data?.payload?.payload?.order_id,
    //                 handler: async (response) => {
    //                     console.log('response--', response);
    //                     await dispatch(
    //                         razorpayStatusInfo({
    //                             payment_id: response?.razorpay_payment_id,
    //                             order_id: response?.razorpay_order_id,
    //                         })
    //                     );
    //                     openModal();
    //                     setTimeout(() => {
    //                         setModalOpen(false);
    //                         location.state
    //                             ? navigate(`${Pathname.ENERGY_BASED}`, {
    //                                   state: location.state,
    //                               })
    //                             : navigate('/wallet');
    //                         //  window.location.reload();
    //                         dispatch(resetWalletTransactions());

    //                         // dispatch(getWalletTransactions({ page: 0 }));
    //                     }, 2500);
    //                     await dispatch(getWalletTransactions({ page: 0 }));
    //                 },
    //                 prefill: {
    //                     name: profileInfo?.payload?.customer?.user_name,
    //                     email: profileInfo?.payload?.customer?.email,
    //                     contact: profileInfo?.payload?.customer?.mobile_number,
    //                 },
    //                 notes: {
    //                     address: profileInfo?.payload?.customer?.address,
    //                 },
    //                 theme: {
    //                     color: '#528ff0',
    //                 },
    //             };

    //             var rzp1 = new window.Razorpay(options);
    //             rzp1.open();
    //         })
    //         .catch((e) => console.log(e));
    // };

    const openPayModal = async () => {
        if (!fromChatBot) {
            // Fetch to get order details
            dispatch(addMoney({ amount: sumValue }))
                .then((data) => {
                    initializeRazorpay(data?.payload?.payload?.order_id);
                })
                .catch((e) => console.log(e));
        } else {
            // If fromChatBot is true, directly initialize Razorpay with a predefined order_id (if available)
            // localStorage.setItem("returnFromRazorpay", "true");

             initializeRazorpay(location.state?.orderId); // Ensure existingOrderId is handled properly
           


        }
    };
    
    const initializeRazorpay = (orderId) => {
        const options = {
            key: razorKey,
            order_id: orderId,
            handler: async (response) => {
                if (!fromChatBot) {
                    console.log('response--', response);
                    await dispatch(
                        razorpayStatusInfo({
                            payment_id: response?.razorpay_payment_id,
                            order_id: response?.razorpay_order_id,
                        })
                    );
                    openModal();
                    setTimeout(() => {
                        setModalOpen(false);
                        location.state
                            ? navigate(`${Pathname.ENERGY_BASED}`, { state: location.state })
                            : navigate('/wallet');
                        dispatch(resetWalletTransactions());
                    }, 2500);
                    await dispatch(getWalletTransactions({ page: 0 }));
                }else{
                    navigate(Pathname.CHAT_BOT);
            dispatch(setReturnFromRazorpay(true)); 
                }
            },
        
            prefill: {
                name: profileInfo?.payload?.customer?.user_name,
                email: profileInfo?.payload?.customer?.email,
                contact: profileInfo?.payload?.customer?.mobile_number,
            },
            notes: {
                address: profileInfo?.payload?.customer?.address,
            },
            theme: {
                color: '#528ff0',
            },
            modal: {
                escape: true, // Allow closing with the escape key
                ondismiss: function () {
                    if (fromChatBot) {
                        dispatch(setReturnFromRazorpay(true)); // Ensure this is dispatched
                        navigate(Pathname.CHAT_BOT); // Navigate back to chatbot if the modal is closed
                    }
                }
            }
        };
    
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    
    
    return (
        <section
            className="AddMoney"
            style={{ height: `calc(100vh - calc(100vh - ${window.innerHeight}px))` }}
        >
            <div className="AddMoney--btn">
                <span
                    onClick={() => {
                        dispatch(resetWalletTransactions());
                        navigate(-1);
                    }}
                >
                    <ArrowLeft />
                </span>
                <p>Add Money</p>
            </div>
            <div className="AddMoney--Input">
                <Input
                    type="number"
                    label="Enter Amount to Add to Wallet"
                    suggestAm={amount}
                    setAmount={setAmount}
                    sumValue={sumValue}
                    onValueChange={(value) => setInputData(value)}
                />
            </div>
            <div className="AddMoney--tag">
                <AddMoneySuggests amount="2000" setAmount={setAmount} />
                <AddMoneySuggests amount="3000" setAmount={setAmount} />
                <AddMoneySuggests amount="4000" setAmount={setAmount} />
                <AddMoneySuggests amount="5000" setAmount={setAmount} />
            </div>
            {/* )} */}
            <div
                style={{ position: 'absolute', bottom: '2rem' }}
                className="SelectView--btn"
            >
                <Button
                    disabled={sumValue ? false : true}
                    onClick={openPayModal}
                    text={sumValue ? `Continue to Add  ${sumValue}` : 'Continue'}
                />
            </div>
            <ModalSucessProgress
                isOpen={modalOpen}
                onClose={closeModal}
                amount={inputData?.moneyAmount}
                data={data}
                text="Added to Wallet"
            />
        </section>
    );
};
export default AddMoney;
