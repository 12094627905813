// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddVehiclesView {
  padding-inline: 2rem;
  background-color: white;
  height: 100vh;
}
.AddVehiclesView .AddVehiclesView--btn {
  display: flex;
  gap: 2rem;
  padding-block: 24px;
  font-weight: 600;
}
.AddVehiclesView .AddVehiclesView--btn p {
  font-size: 18px;
  align-self: center;
}
.AddVehiclesView .AddVehiclesView--add-btn {
  position: absolute;
  bottom: 2rem;
  width: 90%;
}

.select-dropdown {
  outline: none;
  max-width: 100%;
  height: 56px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  color: #333333;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/MyVehiclesView/AddVehiclesView/addvehicleview.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,uBAAA;EACA,aAAA;AACJ;AAAI;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;AAER;AADQ;EACI,eAAA;EACA,kBAAA;AAGZ;AAKI;EACI,kBAAA;EACA,YAAA;EACA,UAAA;AAHR;;AAOA;EACI,aAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAJJ","sourcesContent":[".AddVehiclesView {\n    padding-inline: 2rem;\n    background-color: white;\n    height: 100vh;\n    .AddVehiclesView--btn {\n        display: flex;\n        gap: 2rem;\n        padding-block: 24px;\n        font-weight: 600;\n        p {\n            font-size: 18px;\n            align-self: center;\n        }\n    }\n    .AddVehiclesView--input {\n        div {\n            // margin-block: 2rem;\n        }\n    }\n    .AddVehiclesView--add-btn {\n        position: absolute;\n        bottom: 2rem;\n        width: 90%;\n    }\n}\n\n.select-dropdown {\n    outline: none;\n    max-width: 100%;\n    height: 56px;\n    padding: 10px;\n    border-radius: 12px;\n    border: 1px solid #d9d9d9;\n    color: #333333;\n    font-family: Outfit;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
