import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the navigation hook
import Button from '../Button/Button';
import './SubscriptionExpireModel.scss';

const SubscriptionExpireModel = ({ heading, content, contentClassName, containerClassName, navigateTo,closePopup }) => {
    const navigate = useNavigate(); // Initialize the navigation function

    const handleNavigation = () => {
        if (navigateTo) {
            navigate(navigateTo); // Navigate to the path provided in props
        } 
        if (closePopup) {
            closePopup(); // Close the popup when button is clicked
        }
    };

    return (
        <div className={`SubscriptionExpire--content ${containerClassName || ''}`}>
            <p className="funds">{heading}</p>
            <p className={`fundInfo ${contentClassName || ''}`}>{content}</p>
            <div>
                <Button 
                    className="addWallet" 
                    text="OK" 
                    onClick={handleNavigation} // Add click handler for navigation
                />
            </div>
        </div>
    );
};

export default SubscriptionExpireModel;
