import { createAsyncThunk } from '@reduxjs/toolkit';
import Server from '../../../../../Server';
import { ToastContainer, toast } from 'react-toastify';
import { CALCULATE_ENERGY, CONNECTOR_DETAIL, END_PLAN_SUBSCRIPTION } from '../../../../../API/Endpoint';

export const calculateUnit = createAsyncThunk(
    'home/calculateUnit',
    async ({ connector_id, amount, unit }) => {
        try {
            const res = await Server.post(
                CALCULATE_ENERGY,
                {
                    connector_id: connector_id,
                    amount: amount,
                    energy: unit,
                },
                true
            );

            if (res?.payload?.showError) {
                if (unit) {               
                    toast.error(`${res?.payload?.errorMsg}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: {
                            fontSize: '16px',
                        },
                    });
                }
            } else {
                if (!res?.success) {
                    toast.error(`${res?.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: {
                            fontSize: '16px',
                        },
                    });
                }
            }

            return res;
        } catch (error) {
            console.log('error', error);
            throw error; // Ensure that the error is still thrown so that it can be handled by the calling function if needed
        }
    }
);

export const fetchCalculatedEnergy = createAsyncThunk(
    'home/fetchCalculatedEnergy',
    async ({ connector_id, unit }) => {
        console.log('response2', connector_id);

        try {
            const res = await Server.post(
                CALCULATE_ENERGY,
                {
                    connector_id: connector_id,
                    energy: unit,
                },
                true
            );

            if (res?.payload?.showError) {
                toast.error(`${res?.payload?.errorMsg}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
            } else {
                if (!res?.success) {
                    toast.error(`${res?.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: {
                            fontSize: '16px',
                        },
                    });
                }
            }

            return res;
        } catch (error) {
            console.log('error', error);
            throw error; // Ensure that the error is still thrown so that it can be handled by the calling function if needed
        }
    }
);

export const fetchCalculatedAmount = createAsyncThunk(
    'home/fetchCalculatedAmount',
    async ({ connector_id, amount }) => {
        try {
            const res = await Server.post(
                CALCULATE_ENERGY,
                {
                    connector_id: connector_id,
                    amount: amount,
                },
                true
            );

            if (res?.payload?.showError) {
                toast.error(`${res?.payload?.errorMsg}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
            } else {
                if (!res?.success) {
                    toast.error(`${res?.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: {
                            fontSize: '16px',
                        },
                    });
                }
            }

            return res;
        } catch (error) {
            console.log('error', error);
            throw error; // Ensure that the error is still thrown so that it can be handled by the calling function if needed
        }
    }
);

export const getConnectorDetails = createAsyncThunk(
    'home/getConnectorDetails',
    async (connector_id) => {
        try {
            const res = await Server.get(`${CONNECTOR_DETAIL}/${connector_id}`, true);
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);


export const getEndPlanDetails = createAsyncThunk(
    'home/getEndPlanDetails',
    async (charging_point_id) => {
        try {
            const res = await Server.get(`${END_PLAN_SUBSCRIPTION}?charging_point_id=${charging_point_id
}`, true);
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);
