// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Badges {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #00aa74;
}

.badge-text {
  color: var(--semantic-success, #00aa74);
  font-family: "Outfit";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.faulted {
  color: #ec6a5e;
  background: #feefed;
  border: 1px solid #ec6a5e;
}
.faulted .badge-text {
  color: #ec6a5e;
}

.success {
  color: #00aa74;
  background: #e6f7ef;
  border: 1px solid #00aa74;
}
.success .badge-text {
  color: #00aa74;
}

.unavailable {
  color: #8c8c8c;
  background: #f2f2f2;
  border: 1px solid #8c8c8c;
}
.unavailable .badge-text {
  color: #8c8c8c;
}

.suspended {
  color: #dfa102;
  background: #fff7e3;
  border: 1px solid #dfa102;
}
.suspended .badge-text {
  color: #dfa102;
}`, "",{"version":3,"sources":["webpack://./src/Components/Badges/Badges.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,uCAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AACA;EACI,cAAA;EACA,mBAAA;EACA,yBAAA;AAEJ;AADI;EACI,cAAA;AAGR;;AACA;EACI,cAAA;EACA,mBAAA;EACA,yBAAA;AAEJ;AADI;EACI,cAAA;AAGR;;AACA;EACI,cAAA;EACA,mBAAA;EACA,yBAAA;AAEJ;AADI;EACI,cAAA;AAGR;;AACA;EACI,cAAA;EAEA,mBAAA;EACA,yBAAA;AACJ;AAAI;EACI,cAAA;AAER","sourcesContent":[".Badges {\n    display: flex;\n    padding: 4px 8px;\n    justify-content: center;\n    align-items: center;\n    border-radius: 8px;\n    border: 1px solid #00aa74;\n    // height: 20px;\n}\n.badge-text {\n    color: var(--semantic-success, #00aa74);\n    font-family: 'Outfit';\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}\n.faulted {\n    color: #ec6a5e;\n    background: #feefed;\n    border: 1px solid #ec6a5e;\n    .badge-text {\n        color: #ec6a5e;\n    }\n}\n\n.success {\n    color: #00aa74;\n    background: #e6f7ef;\n    border: 1px solid #00aa74;\n    .badge-text {\n        color: #00aa74;\n    }\n}\n\n.unavailable {\n    color: #8c8c8c;\n    background: #f2f2f2;\n    border: 1px solid #8c8c8c;\n    .badge-text {\n        color: #8c8c8c;\n    }\n}\n\n.suspended {\n    color: #dfa102;\n\n    background: #fff7e3;\n    border: 1px solid #dfa102;\n    .badge-text {\n        color: #dfa102;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
