// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 10px 0;
}
.Footer .heading {
  text-align: center;
  font-family: "Outfit";
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  color: #4c4c4c;
}
.Footer .terms {
  color: #00aa74;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Footer/Footer.scss","webpack://./src/Styles/sass/_variables.scss"],"names":[],"mappings":"AAEA;EAEI,WAAA;EACA,eAAA;EACA,SAAA;EACA,eAAA;AAFJ;AAKI;EACI,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cCMU;ADTlB;AAKI;EACI,cCnBQ;ADgBhB","sourcesContent":["@import '../../../Styles/sass/variables';\n\n.Footer {\n  \n    width: 100%;\n    position: fixed;\n    bottom: 0;\n    padding: 10px 0;\n\n\n    .heading {\n        text-align: center;\n        font-family: 'Outfit';\n        font-size: 14px;\n        font-weight: 300;\n        line-height: 18px;\n        letter-spacing: 0em;\n        color: $color_black_100;\n    }\n    .terms{\n        color:$primary_green;\n    }\n}\n","//Primary shades\n$primary_green: #00aa74;\n$primary_light: #e6f7ef;\n$primary_gradient: linear-gradient(109.42deg, #00aa74 0%, #16eba6 100%, #16eba6 100%);\n$primary_gradient1: linear-gradient(108deg, #00AA74 -78.7%, #1E1E1E 59.58%);\n\n//secondary shades\n$secondary_dark: #012515;\n\n//semantic\n$semantic_red: #ec6a5e;\n$semantic_orange: #dfa102;\n\n//Grey Shades\n$color_grey_400: #8c8c8c;\n$color_grey_300: #b3b3b3;\n$color_grey_200: #d9d9d9;\n$color_grey_100: #f2f2f2;\n\n//Black Shades\n$color_black_400: #000000;\n$color_black_300: #1e1e1e;\n$color_black_200: #333333;\n$color_black_100: #4c4c4c;\n\n//green shades\n$color_green_100: #e6f7ef;\n$color_green_150: #c2ead8;\n$color_green_200: #9adcbf;\n$color_green_300: #6ecfa6;\n$color_green_400: #48c493;\n$color_green_500: #09ba80;\n$color_green_600: #009766;\n$color_green_700: #00865a;\n$color_green_800: #006643;\n\n//Fontstyles\n$main_font: 'Syne', sans-serif;\n$secondary_font: 'Outfit', sans-serif;\n$tertiary_font: 'Space Grotesk', sans-serif;\n$text_size: 1rem;\n$dashboardtext: #575757;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
