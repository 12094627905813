import React from "react";
import Button from "../../../Components/Common/Button/Button"; // Ensure this path is correct
import { useNavigate } from "react-router-dom";
import { Pathname } from "../../../Router/Pathname";

const RefundCard = ({ refund }) => {
  const navigate = useNavigate();
  if (!refund) {
    return null; // Return nothing if refund data is empty
  }

  return (
    <div className="payment-container">
      <p className="payment-header">{refund.message}</p>

      {!!refund?.refundAmount && <div className="payment-card">
        <p className="payment-title">Refund of</p>
        <h2 className="payment-amount">{refund.refundAmount}</h2>
      </div>}
      {/* <p className="refund-info">{refund.info}</p> */}
      {refund?.showSupportBtn && <Button text={refund.buttonText} variant="chatButton" className="payment-button" onClick={()=>navigate(Pathname.HELP_AND_SUPPORT)}/>}
    </div>
  );
};

export default RefundCard;
