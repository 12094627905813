import React from "react";
import StationIcon from '../../../Assets/pngIcon/stationImage.png';


const StationsCard = ({ stations, handleSelectStation, heading }) => {
  if (!stations || stations.length === 0) {
    return null; // Return nothing if stations list is empty
  }

  return (
    <div className="station-wrapper">
    <div className="station-list">
      {stations.map((station, idx) => (
        <div key={idx} onClick={() => handleSelectStation(station)}
>
          {/* {idx === 0 && <p className="station-heading">{heading}</p>} */}
          <div className="station-card">
            <img src={StationIcon|| station.image} alt={station.name} className="station-image" />
            <div className="station-details">
              <p className="station-name">{station.name}</p>
              <p className="station-session">Last session: {station.lastSession}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
    </div>

  );
};

export default StationsCard;

