import React, { useState } from 'react';
import './style.scss';
import PaymentReceiptIcon from '../../../Assets/icons/PaymentReceiptIcon';
import ClockIcon from '../../../Assets/icons/ClockIcon';
import ElectricityIcon from '../../../Assets/icons/ElectricityIcon';
import InvoiceDownload from '../InvoiceDownload/InvoiceDownload';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
    getChargingInvoice,
    getRefundInvoice,
} from '../../../Views/AfterAuth/WalletView/redux/walletSlice';
import Reload from '../../../Assets/icons/Reload';
import PreLoader from '../../../Assets/icons/PreLoader/PreLoader';
import RefundInvoiceDownload from '../RefundInvoiceDownload/RefundInvoiceDownload';

const MyTransactioncard = ({
    amount,
    station,
    mode,
    lastChargingTime,
    element,
    check_invoice,
    transac_mode,
    txn_status,
    handleReload,
    transactionTime,
    requestedReloadPaymentId,
    paymentId,
    orderId,
    id,
}) => {
    const { walletTransactions } = useSelector((store) => store.wallet);
    const dispatch = useDispatch();
    const amoun = amount.slice(0, 1);
    const [invoiceModal, setinvoiceModal] = useState(false);
    const [refundInvoiceModal, setRefundInvoiceModal] = useState(false);

    const invoiceOpen = () => {
        setinvoiceModal(true);
        dispatch(getChargingInvoice({ id: element }));
    };

    const invoiceClose = () => {
        setinvoiceModal(false);
    };

    /*Refund Invoice Download*/
    const refundInvoiceOpen = () => {
        setRefundInvoiceModal(true);
        dispatch(getRefundInvoice({ id: id }));
    };
    const refundInvoiceClose = () => {
        setRefundInvoiceModal(false);
    };

    const transactionStatus = () => {
        switch (true) {
            case txn_status?.toLowerCase() === 'failure':
            case check_invoice?.toLowerCase() !== 'topup':
                return 'send';
            case txn_status?.toLowerCase() === 'pending':
            case txn_status?.toLowerCase() === 'initiated':
                return 'pending';
            default:
                return 'received';
        }
    };
    return (
        <div>
            <div className="main-content">
                <div>
                    <div className="text">
                        <p>
                            {check_invoice?.toLowerCase() === 'topup'
                                ? txn_status?.toLowerCase() === 'failure'
                                    ? 'Failed to Add Wallet'
                                    : txn_status?.toLowerCase() === 'pending'
                                    ? 'Pending to Add Wallet'
                                    : txn_status?.toLowerCase() === 'initiated'
                                    ? 'Tried adding to wallet'
                                    : 'Added to Wallet'
                                : check_invoice?.toLowerCase() === 'refund'
                                ? 'Refund'
                                : station}
                        </p>
                        <p className={transactionStatus()}>
                            {check_invoice?.toLowerCase() === 'topup'
                                ? ` +₹${amount}`
                                : ` -₹${amount}`}
                        </p>
                    </div>
                    <div className="time">
                        <span>
                            <ClockIcon />
                        </span>{' '}
                        <p>{dayjs(transactionTime).format('HH:mm a')}</p>
                        <span>
                            <ElectricityIcon />
                        </span>
                        {transac_mode}
                        {/* <p>{mode}</p> */}
                    </div>
                </div>
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        // gap: '10px',
                        alignItems: 'center',
                    }}
                    className="icon"
                >
                    {orderId && (
                        <div>
                            {txn_status?.toLowerCase() === 'pending' ||
                            txn_status?.toLowerCase() === 'initiated' ? (
                                <span onClick={handleReload}>
                                    {requestedReloadPaymentId === orderId ? (
                                        <PreLoader className="main-content--preloader" />
                                    ) : (
                                        <Reload />
                                    )}
                                </span>
                            ) : null}
                        </div>
                    )}

                    <div
                        onClick={() => {
                            if (check_invoice?.toLowerCase() === 'refund') {
                                refundInvoiceOpen();
                            } else {
                                invoiceOpen()
                            }
                        }}
                    >
                        {check_invoice?.toLowerCase() === 'topup' ? (
                            ' '
                        ) : (
                            <PaymentReceiptIcon />
                        )}
                    </div>
                </div>
            </div>
            <InvoiceDownload
                element={element}
                openModal={invoiceModal}
                invoiceClose={invoiceClose}
            />
            {check_invoice?.toLowerCase() === 'refund' ? (
                <RefundInvoiceDownload
                    element={id}
                    openModal={refundInvoiceModal}
                    invoiceClose={refundInvoiceClose}
                />
            ) : (
                ''
            )}
        </div>
    );
};
export default MyTransactioncard;
