// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionExpire--content {
  padding-inline: 3rem; }

@media screen and (max-width: 580px) {
  .SubscriptionExpire--content {
    padding-inline: 0rem; } }
`, "",{"version":3,"sources":["webpack://./src/Components/Common/SubscriptionExpireModel/SubscriptionExpireModel.scss"],"names":[],"mappings":"AACA;EACI,oBAAoB,EAAA;;AAGxB;EACI;IACI,oBAAoB,EAAA,EACvB","sourcesContent":["\n.SubscriptionExpire--content{\n    padding-inline: 3rem;\n}\n\n@media screen and (max-width: 580px) {\n    .SubscriptionExpire--content {\n        padding-inline: 0rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
