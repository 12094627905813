import React, { useEffect, useState } from 'react';
import './SearchConnector.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from '../../../../Components/Common/Input/Input';
import MiniConnectorCard from '../../../../Components/MiniConnectorCard/MiniConnectorCard';
import { Pathname } from '../../../../Router/Pathname';
import { getConnectorById } from '../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import PreLoader from '../../../../Assets/icons/PreLoader/PreLoader';
import ConnectorHeader from './ConnectorHeader';
const SearchConnector = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { stations } = useSelector((store) => store);
    const { connectorDetails, loading } = stations;
    const [searchPoint, setSearchPoint] = useState();

    const handleChargingPointSearch = (value) => {
        setSearchPoint(value);
    };
    const handleNavigate = (connector) => {
        console.log('connector?.connector_meta_cp_status: ', connector);
        const isConnectorAvailable = connector?.connector_meta_cp_status === 'Available' ||
            connector?.connector_meta_cp_status === 'Preparing';
        if (!isConnectorAvailable) return;

        navigate(Pathname.ENERGY_BASED, {
            state: {
                connector_id: connector?.connector_meta_cp_id,
                charging_point_name: connector?.charging_point_name,
            },
        })
    }

    const renderConnectorCard = () => {
        return connectorDetails?.map((connector) => (
            <MiniConnectorCard
                key={connector?.id}
                name={`${connector?.charging_point_name}  
                          
                             `}
                io_type={`${connector?.id}-${connector?.connector_meta_cp_name} ${connector?.connector_io_type}  `}
                status={connector?.connector_meta_cp_status}
                onClick={() => handleNavigate(connector)}
            />
        ));
    };
    useEffect(() => {
        if (location?.state) {
            const debouncedStationName = setTimeout(() => {
                dispatch(
                    getConnectorById({
                        station_id: location?.state?.id,
                        charging_point_name: searchPoint,
                    })
                );
            }, 500);
            return () => clearTimeout(debouncedStationName);
        }
    }, [searchPoint]);

    return (
        <main className="SearchConnector">
            <ConnectorHeader stationName={location?.state?.name} />
            <div className="SearchConnector__Input">
                <Input
                    label={'Search Connectors'}
                    onValueChange={handleChargingPointSearch}
                />
            </div>

            <div className="SearchConnector__Cards">
                {connectorDetails?.length > 0 ? (
                    renderConnectorCard()
                ) : loading ? (
                    <div className="SearchConnector__Preloader">
                        <PreLoader />
                    </div>
                ) : (
                    <h1>No connectors for this station</h1>
                )}
            </div>
        </main>
    );
};

export default SearchConnector;
