export const VEHICLE_GET_VEHICLE = `/api/v1/vehicle/`;
export const VEHICLE_CREATE_VEHICLE = `/api/v1/vehicle/`;
export const VEHICLE_DELETE_VEHICLE = `/api/v1/vehicle/`;
export const VEHICLE_DEFAULT_VEHICLE = `/api/v1/vehicle`;
export const SEARCH_STATIONS = `/api/v1/customer/ocpp/get-charging-station`;
export const CONNECTOR_STANDARD = '/api/v1/customer/ocpp/enums/connector-standard';
export const FETCH_CONNECTOR_BY_STATION =
    '/api/v1/customer/ocpp/fetch-connector?charging_point_id=testevre';
export const CALCULATE_ENERGY = '/api/v1/customer/ocpp/calculate-total-unit';
export const CONNECTOR_DETAIL = '/api/v1/customer/ocpp/get-connector-detail';
export const END_PLAN_SUBSCRIPTION = '/api/v1/csms/ocpp/customer/client-subscription';
export const CHAT_BOT_API='/api/v1/n8n/chat';

