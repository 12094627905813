// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeView--Header {
  position: relative;
  z-index: 1;
  margin-top: -12rem;
  padding-top: 11rem;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: white;
  padding-inline: 2rem;
  height: 75vh;
}
.HomeView--Header .HomeView--profileName {
  font-size: 2.5rem;
  margin: 1.5rem 1rem;
}
.HomeView--Header .HomeView--Card {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.HomeView {
  margin-bottom: 14rem;
}

@media screen and (max-width: 360px) {
  .responsive--homeView {
    margin-bottom: 12rem;
  }
  .responsive--header {
    margin-top: -10rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/HomeView/HomeView.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,UAAA;EACA,kBAAA;EACA,kBAAA;EACA,4BAAA;EACA,6BAAA;EACA,uBAAA;EACA,oBAAA;EACA,YAAA;AAAJ;AAEI;EACI,iBAAA;EACA,mBAAA;AAAR;AAGI;EACI,aAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AADR;;AAKA;EACI,oBAAA;AAFJ;;AAKA;EACI;IACI,oBAAA;EAFN;EAKE;IACI,kBAAA;EAHN;AACF","sourcesContent":["@import '../../../Styles/sass/variables';\n.HomeView--Header {\n    position: relative;\n    z-index: 1;\n    margin-top: -12rem;\n    padding-top: 11rem;\n    border-top-left-radius: 24px;\n    border-top-right-radius: 24px;\n    background-color: white;\n    padding-inline: 2rem;\n    height: 75vh;\n\n    .HomeView--profileName {\n        font-size: 2.5rem;\n        margin: 1.5rem 1rem;\n    }\n\n    .HomeView--Card{\n        display: flex;\n        gap: 2rem;\n        justify-content: space-between;\n        margin-bottom: 3rem;\n        margin-top: 3rem;\n    }\n}\n\n.HomeView{\n    margin-bottom: 14rem;\n}\n\n@media screen and (max-width: 360px) {\n    .responsive--homeView {\n        margin-bottom: 12rem;\n    }\n\n    .responsive--header {\n        margin-top: -10rem;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
