// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntiateCharging {
  padding-inline: 20px;
  position: relative;
  background-color: #f8f8f8;
}

.IntiateCharging--btn {
  display: flex;
  gap: 2rem;
  padding-block: 24px;
  font-weight: 600;
}
.IntiateCharging--btn p {
  font-size: 18px;
  align-self: center;
}

.IntiateCharging--card {
  padding-block: 4rem;
}
.IntiateCharging--card div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 2rem;
}

.IntiateCharging--content {
  padding-inline: 3rem;
}
.IntiateCharging--content p:first-child {
  padding-block: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/StartChargeingView/IntiateChargingView/intiatechargingview.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,kBAAA;EAEA,yBAAA;AAAJ;;AAEA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAAI;EACI,eAAA;EACA,kBAAA;AAER;;AAEA;EACI,mBAAA;AACJ;AAAI;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,gBAAA;AAER;;AAEA;EACI,oBAAA;AACJ;AAAI;EACI,qBAAA;AAER","sourcesContent":[".IntiateCharging {\n    padding-inline: 20px;\n    position: relative;\n    // height: 100vh;\n    background-color: #f8f8f8;\n}\n.IntiateCharging--btn {\n    display: flex;\n    gap: 2rem;\n    padding-block: 24px;\n    font-weight: 600;\n    p {\n        font-size: 18px;\n        align-self: center;\n    }\n}\n\n.IntiateCharging--card {\n    padding-block: 4rem;\n    div {\n        display: flex;\n        justify-content: space-between;\n        width: 100%;\n        column-gap: 2rem;\n    }\n}\n\n.IntiateCharging--content{\n    padding-inline: 3rem;\n    p:first-child{\n        padding-block: .5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
