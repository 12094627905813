// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Typography--Link {
  text-decoration: underline;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
}
.Typography--Link:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Typography/Typography.scss"],"names":[],"mappings":"AAEI;EAEI,0BAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFR;AAIQ;EACI,eAAA;AAFZ","sourcesContent":["\n.Typography {\n    &--Link {\n//         color: $color-blue-200;\n        text-decoration: underline;\n        font-size: 1.4rem;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 2rem;\n\n        &:hover {\n            cursor: pointer;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
