import { createAsyncThunk } from '@reduxjs/toolkit';
import ChatBot from '../ChatBot';
import Server from '../../../Server';
import { toast } from 'react-toastify';
import { CHAT_BOT_API } from '../../../API/Endpoint';


export const createChat = createAsyncThunk(
    "chatbot/createChat",
    async ({ body }, thunkAPI) => {
        console.log(body, "body");
        try {
            const res = await Server.post(CHAT_BOT_API, body, true);
            console.log(res, "createChat Response");

            if (res?.success) {
                return res.payload; // 
            } else {
                toast.error("Failed to create chat", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: { fontSize: "16px" },
                });
                return thunkAPI.rejectWithValue("Failed to create chat");
            }
        } catch (err) {
            console.error("API Error:", err);
            console.log("Error Response:", err.response);

            return thunkAPI.rejectWithValue(
                err?.response?.data?.message || "Something went wrong"
            );
        }
    }
);








