// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectView {
  padding-inline: 20px;
  position: relative;
  background-color: #f8f8f8;
  height: 100vh;
}

.SelectView--Select {
  display: flex;
  gap: 2rem;
  padding-block: 24px;
  font-weight: 600;
}
.SelectView--Select p {
  font-size: 18px;
  align-self: center;
}

.SelectView--text p {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.SelectView--card {
  padding-block: 4rem;
}

.SelectView--btn {
  position: relative;
  margin: auto;
  bottom: 2rem;
  width: 90%;
}

.connectors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/StartChargeingView/SelectConnectionView/selectview.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;AACJ;;AACA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AADI;EACI,eAAA;EACA,kBAAA;AAGR;;AACI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAER;;AACA;EACI,mBAAA;AAEJ;;AACA;EACI,kBAAA;EACA,YAAA;EACA,YAAA;EACA,UAAA;AAEJ;;AAAA;EACI,aAAA;EACA,qCAAA;EACA,SAAA;EACA,6BAAA;AAGJ","sourcesContent":[".SelectView{\n    padding-inline: 20px;\n    position: relative;\n    background-color: #f8f8f8;\n    height: 100vh;\n}\n.SelectView--Select{\n    display: flex;\n    gap: 2rem;\n    padding-block: 24px;\n    font-weight: 600;\n    p{\n        font-size: 18px;\n        align-self: center;\n    }\n}\n.SelectView--text{\n    p{\n        font-size: 20px;\n        font-weight: 700;\n        line-height: 24px;\n    }\n}\n.SelectView--card{\n    padding-block: 4rem;\n \n}\n.SelectView--btn{\n    position: relative;\n    margin: auto;\n    bottom: 2rem;\n    width: 90%;\n}\n.connectors{\n    display: grid;\n    grid-template-columns: repeat(2,1fr);\n    gap: 10px;\n    justify-content: space-around;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
