import React from 'react';
import './Sidebar.scss';
import ArrowLeft from '../../../Assets/icons/ArrowLeft';
import walletIcon from '../../../Assets/icons/down-payment.svg';
import chargingIcon from '../../../Assets/icons/wireless-charging.svg';
import faqIcon from '../../../Assets/icons/information.svg';
import { Pathname } from '../../../Router/Pathname';
import { useNavigate } from 'react-router-dom';
import { setLeoButton } from '../Redux/reducer';
import { useDispatch } from 'react-redux';

const Sidebar = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    return (
        <>
            {/* Sidebar */}
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-header">
                    <span onClick={onClose}>
                        <ArrowLeft />
                    </span>
                    <h3 className="sidebar-heading">Chats</h3>
                </div>
                <div
                    style={{ display: 'flex', marginTop: '1rem' }}
                    onClick={() => {
                        dispatch(setLeoButton('charging_assistant'));
                        onClose(); // Call onClose after dispatch
                    }}
                >
                    <img
                        src={chargingIcon}
                        alt={'chargingPoint.name'}
                        className="bot-button-image"
                    />
                    <p className="bot-button-name" style={{ paddingTop: '1rem' }}>
                        Charging Assistant
                    </p>
                </div>

                <div
                    style={{ display: 'flex', marginTop: '1rem' }}
                    onClick={() => {
                        dispatch(setLeoButton('payment_assistant'));
                        onClose(); // Call onClose after dispatch
                    }}
                >
                    <img
                        src={walletIcon}
                        alt={'chargingPoint.name'}
                        className="bot-button-image"
                    />
                    <p className="bot-button-name" style={{ paddingTop: '1rem' }}>
                        Payment Assistant
                    </p>
                </div>

                <div style={{ display: 'flex', marginTop: '1rem' }}>
                    <img
                        src={faqIcon}
                        alt={'chargingPoint.name'}
                        className="bot-button-image"
                    />
                    <p className="bot-button-name" style={{ paddingTop: '1rem' }}>
                        Faq Assistant
                    </p>
                </div>

                <div className="chat-list">
                    <div className="chat-section">
                        <p className="chat-date">Today</p>
                        <div className="chat-item">
                            How much does it cost to charge my EV?
                        </div>
                        <div className="chat-item">Find the nearest charging station</div>
                    </div>
                    <div className="chat-section">
                        <p className="chat-date">Yesterday</p>
                        <div className="chat-item">Stop my charging session</div>
                        <div className="chat-item">
                            Show available charging stations near me
                        </div>
                    </div>
                </div>
                <button className="new-chat-btn" onClick={onClose}>
                    + New Chat
                </button>
            </div>
        </>
    );
};

export default Sidebar;
