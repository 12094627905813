import optSlice from '../Views/beforeAuth/LoginView/redux/actionCreator';
import profileInformation from '../Views/AfterAuth/MyProfileView/redux/profileSlice';
import homeSlice from '../Views/AfterAuth/HomeView/redux/homeSlice';
import walletSlice from '../Views/AfterAuth/WalletView/redux/walletSlice';
import vehicleSlice from '../Views/AfterAuth/MyVehiclesView/Redux/reducer';
import SearchStationSlice from '../Views/AfterAuth/SearchStationsView/redux/SearchStationSlice';
import EnergySlice from '../Views/AfterAuth/StartChargeingView/EnergyCalculations/Redux/EnergySlice';
import  chatBotSlice  from '../Pages/ChatBot/Redux/reducer';

export const rootReducer = {
    otp: optSlice,
    profile: profileInformation,
    home: homeSlice,
    wallet: walletSlice,
    vehicle: vehicleSlice,
    stations: SearchStationSlice,
    energy: EnergySlice,
    chatBot:chatBotSlice,
};
