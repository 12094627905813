// export const capitalizeFirstLetter = (str) => {
//     return str.charAt(0).toUpperCase() + str.slice(1);
// };

    //Generate Time of Day
   export const getTimeOfDay = () => {
        const hours = new Date().getHours();
        if (hours >= 5 && hours < 12) {
            return "Good Morning";
        } else if (hours >= 12 && hours < 17) {
            return "Good Afternoon";
        } else if (hours >= 17 && hours < 20) {
            return "Good Evening";
        } else {
            return "Good Night";
        }
    };
    // Generate and store the session ID only once

  export  const generateFakeUUID=()=> {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }