// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchStations {
  padding-inline: 20px;
  position: relative;
}
.SearchStations__Header {
  display: flex;
  align-items: center;
  padding-block: 3rem;
  gap: 16px;
}
.SearchStations__Input {
  padding: 20px 0px;
}
.SearchStations__Left {
  display: block;
}
.SearchStations__Right {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.SearchStations__Title {
  color: #1e1e1e;
  font-family: Syne;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
}
.SearchStations__FilterContainer {
  display: flex;
  gap: 8px;
  border: none;
  margin-bottom: 25px;
  overflow-x: scroll;
}
.SearchStations__Cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.SearchStations__Loader {
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/SearchStationsView/SearchStations/SearchStations.scss"],"names":[],"mappings":"AAGA;EACI,oBAAA;EACA,kBAAA;AAFJ;AAGI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AADR;AAGI;EACI,iBAAA;AADR;AAGI;EACI,cAAA;AADR;AAGI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AADR;AAGI;EACI,cAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;AADR;AAGI;EACI,aAAA;EACA,QAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AADR;AAGI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AADR;AAGI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AADR","sourcesContent":["@import '../../../../Styles/sass/variables';\n@import '../../../../Styles/sass/mixins';\n\n.SearchStations {\n    padding-inline: 20px;\n    position: relative;\n    &__Header {\n        display: flex;\n        align-items: center;\n        padding-block: 3rem;\n        gap: 16px;\n    }\n    &__Input {\n        padding: 20px 0px;\n    }\n    &__Left {\n        display: block;\n    }\n    &__Right {\n        display: flex;\n        flex-direction: column;\n        gap: 2px;\n    }\n    &__Title {\n        color: #1e1e1e;\n        font-family: Syne;\n        font-size: 18px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: normal;\n        letter-spacing: 0.36px;\n    }\n    &__FilterContainer {\n        display: flex;\n        gap: 8px;\n        border: none;\n        margin-bottom: 25px;\n        overflow-x: scroll;\n    }\n    &__Cards {\n        display: flex;\n        flex-direction: column;\n        gap: 24px;\n    }\n    &__Loader {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
