import React from "react";
// import './ChatBot.scss';
import PointIcon from '../../../Assets/icons/pointImage.svg';


const ChargingPointCard = ({ chargingPoints }) => {
  if (!chargingPoints || chargingPoints.length === 0) {
    return null; // Return nothing if no charging points are available
  }

  return (
    <div className="station-wrapper">
    <div className="station-list">
      {/* Heading shown only once */}
      <p className="station-heading">
        Currently, there are {chargingPoints.length} charging points available
      </p>

      {chargingPoints.map((chargingPoint, idx) => (
        <div key={idx} className="station-card">
          <img
            src={PointIcon}
            alt={chargingPoint.name}
            className="station-image"
          />
          <div className="station-details">
            <p className="station-name">{chargingPoint.name}</p>
            <p className="station-session">
              {chargingPoint.power} • {chargingPoint.price}
            </p>
            <p className="station-session">
              Last session: {chargingPoint.lastSession}
            </p>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default ChargingPointCard;
