// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leo-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #EAF9F1; /* Light greenish background */
  border: 1px solid #00a65a; /* Green border */
  border-radius: 50px; /* Fully rounded */
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  width: 100%; /* Initially full width */
  max-width: 500px; /* Prevents it from being too wide on large screens */
  min-width: 220px; /* Ensures it doesn't get too small */
  margin-bottom: 1rem;
  margin-top: 2;
}

.leo-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.leo-button-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}

/* Makes it smaller on mobile screens */
@media (max-width: 500px) {
  .leo-button {
    max-width: 100%;
    font-size: 18px;
    padding: 10px 16px;
  }
}
.text {
  flex-grow: 1;
  color: #4C4C4C;
  font-family: "Outfit";
  font-weight: 400;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/LeoButton/LeoButton.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA,EAAA,8BAAA;EACA,yBAAA,EAAA,iBAAA;EACA,mBAAA,EAAA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,yBAAA;EACA,WAAA,EAAA,yBAAA;EACA,gBAAA,EAAA,qDAAA;EACA,gBAAA,EAAA,qCAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AACJ;;AAEA,uCAAA;AACA;EACI;IACI,eAAA;IACA,eAAA;IACA,kBAAA;EACN;AACF;AAEA;EACI,YAAA;EACA,cAAA;EACA,qBAAA;EACA,gBAAA;EACA,eAAA;AAAJ","sourcesContent":[".leo-button {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background: #EAF9F1; /* Light greenish background */\n    border: 1px solid #00a65a; /* Green border */\n    border-radius: 50px; /* Fully rounded */\n    padding: 12px 20px;\n    font-size: 16px;\n    font-weight: 500;\n    color: #333;\n    cursor: pointer;\n    outline: none;\n    transition: all 0.3s ease;\n    width: 100%; /* Initially full width */\n    max-width: 500px; /* Prevents it from being too wide on large screens */\n    min-width: 220px; /* Ensures it doesn't get too small */\n    margin-bottom: 1rem;\n    margin-top: 2;\n}\n\n.leo-button-wrapper{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 4rem;\n}\n\n.leo-button-inner{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 9px;\n}\n\n/* Makes it smaller on mobile screens */\n@media (max-width: 500px) {\n    .leo-button {\n        max-width: 100%;\n        font-size: 18px;\n        padding: 10px 16px;\n    }\n}\n\n.text {\n    flex-grow: 1;\n    color: #4C4C4C;\n    font-family: 'Outfit';\n    font-weight: 400;\n    font-size: 16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
