// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HelpandSupportView {
  padding-inline: 2rem;
  height: 100vh;
  background-color: white;
}
.HelpandSupportView .HelpandSupportView--navigate {
  display: flex;
  gap: 2rem;
  padding-block: 24px;
  font-weight: 600;
}
.HelpandSupportView .HelpandSupportView--navigate p {
  font-size: 18px;
  align-self: center;
}
.HelpandSupportView .HelpandSupportView--content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.HelpandSupportView .HelpandSupportView--content p:first-child {
  font-size: 18px;
  line-height: 21.7px;
  font-weight: 600;
}
.HelpandSupportView .HelpandSupportView--content div {
  display: flex;
  gap: 2rem;
}
.HelpandSupportView .HelpandSupportView--content div p {
  font-size: 16px;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/MyProfileView/HelpandSupportView/helpandsupport.scss"],"names":[],"mappings":"AACA;EACI,oBAAA;EACA,aAAA;EACA,uBAAA;AAAJ;AACI;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;AACR;AAAQ;EACI,eAAA;EACA,kBAAA;AAEZ;AACI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACR;AAAQ;EACI,eAAA;EACA,mBAAA;EACA,gBAAA;AAEZ;AAAQ;EACI,aAAA;EACA,SAAA;AAEZ;AADY;EACI,eAAA;EACA,iBAAA;AAGhB","sourcesContent":["\n.HelpandSupportView{\n    padding-inline: 2rem;\n    height: 100vh;\n    background-color: white;\n    .HelpandSupportView--navigate{\n        display: flex;\n        gap: 2rem;\n        padding-block: 24px;\n        font-weight: 600;\n        p{\n            font-size: 18px;\n            align-self: center;\n        }\n    }\n    .HelpandSupportView--content{\n        display: flex;\n        flex-direction: column;\n        gap: 3rem;\n        p:first-child{\n            font-size: 18px;\n            line-height: 21.7px;\n            font-weight: 600;\n        }\n        div{\n            display: flex;\n            gap: 2rem;\n            p{\n                font-size: 16px;\n                line-height: 22px;\n\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
